import React from "react";
import {
    Dropdown,
    Form,
    Input,
    Checkbox,
    Popup,
} from "semantic-ui-react";

import {
    setsGoalType,
    setsTimeGoalType,
    timeUnit,
} from "../../data/constants";


function SetsGoals({ setsGoals, setSetsGoals }) {

    const onChangeSetsGoalsHandler = ({ target: { name, value } }) => {
        if (name === "setsNumber" || name === "restTimeBetweenSets") {
            setSetsGoals((prevState) => {
                return {
                    ...prevState,
                    [name]: Number(value),
                };
            });
        }
        else {
            setSetsGoals((prevState) => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        }
    };

    const dropDownGoalsHandler = (_, data) => {
        if (data.name === "setsGoalType") {
            setSetsGoals((prevState) => {
                return {
                    ...prevState,
                    setsGoalType: data.value,
                };
            });
        }

        else if (data.name === "timeGoalType") {
            setSetsGoals((prevState) => {
                return {
                    ...prevState,
                    timeGoalType: data.value,
                };
            });
        }
        else if (data.name === "timeUnit") {
            setSetsGoals((prevState) => {
                return {
                    ...prevState,
                    timeUnit: data.value,
                };
            });
        }
    };


    return (
        <section className="form-control-session">
            <header className="movement">
                {"Sets Goals"}
            </header>
            <form autoComplete="on" className="form--movement--control">
                {/* <Form.Field> */}
                    <Popup
                        trigger={
                            <Input
                                placeholder={"Target sets number"}
                                className="equipment-select"
                                type="text"
                                name="setsNumber"
                                onChange={onChangeSetsGoalsHandler}
                                value={setsGoals.setsNumber || ""}
                            />
                        }
                        content="Target sets number"
                    />
                {/* </Form.Field> */}

                {/* <Form.Field> */}
                    {/* <Popup
                        trigger={
                            <Dropdown
                                placeholder="Sets Goal Type"
                                options={setsGoalType}
                                onChange={dropDownGoalsHandler}
                                className="equipment-select"
                                name="setsGoalType"
                                selectOnBlur={false}
                                value={setsGoals.setsGoalType || ""}
                                openOnFocus
                                selection
                            />
                        }
                        content="Sets Goal Type"
                    /> */}
                {/* </Form.Field> */}

                {/* <Form.Field> */}
                    {/* <Popup
                        trigger={
                            <Dropdown
                                placeholder="Time Goal Type"
                                options={setsTimeGoalType}
                                onChange={dropDownGoalsHandler}
                                className="equipment-select"
                                name="timeGoalType"
                                value={setsGoals.timeGoalType || ""}
                                openOnFocus
                                selectOnBlur={false}
                                selection
                            />
                        }
                        content="Time Goal Type"
                    /> */}
                {/* </Form.Field> */}

                {/* <Form.Field> */}
                    <Popup
                        trigger={
                            <Input
                                placeholder="Time per set (Seconds)"
                                className="equipment-select"
                                type="text"
                                name="timePerSet"
                                onChange={onChangeSetsGoalsHandler}
                                value={setsGoals.timePerSet || ""}
                            />
                        }
                        content="Time per Set"
                    />
                {/* </Form.Field> */}
                {/* <Form.Field> */}
                    {/* <Popup
                        trigger={
                            <Input
                                placeholder="Sets total time"
                                className="equipment-select"
                                type="text"
                                name="setTotalTime"
                                onChange={onChangeSetsGoalsHandler}
                                value={setsGoals.setTotalTime || ""}
                            />
                        }
                        content="Sets Total Time"
                    /> */}
                {/* </Form.Field> */}
                {/* <Form.Field> */}
                    {/* <Popup
                        trigger={
                            <Dropdown
                                placeholder="Time unit"
                                options={timeUnit}
                                onChange={dropDownGoalsHandler}
                                className="equipment-select"
                                name="timeUnit"
                                value={setsGoals.timeUnit || ""}
                                selection
                                openOnFocus
                                selectOnBlur={false}
                            />
                        }
                        content="Time Unit"
                    /> */}
                {/* </Form.Field> */}
                {/* <article className="radio-control">
                        <Checkbox
                            className="radio"
                            label="Use different reps per set"
                            name="useDifferentRepsPerSet"
                            type="checkbox"
                            onChange={onChangeSetsGoalsHandler}
                            defaultChecked={false}
                        />
                </article> */}
                {/* <Form.Field> */}
                    <Popup
                        trigger={
                            <Input
                                placeholder="Rest Time Between Sets (in Seconds)"
                                className="equipment-select"
                                type="text"
                                name="restTimeBetweenSets"
                                onChange={onChangeSetsGoalsHandler}
                                value={setsGoals.restTimeBetweenSets || ""}
                            />
                        }
                        content="Rest Time Between Sets"
                    />
                {/* </Form.Field> */}
            {/* </Form> */}
            </form>
        </section>
    );
}

export default SetsGoals;