import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";
import { useRouteMatch, Link, useLocation } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";

import axios from "axios";
import ReactPlayer from 'react-player'

import { HttpRequest } from "@aws-sdk/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
import { Hash } from "@aws-sdk/hash-node";
import { formatUrl } from "@aws-sdk/util-format-url";

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const userSessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_USER_SESSIONS_SUFFIX;
const userSessionsURL = serverURL + userSessionsSuffix;

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;

var credentials = {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region
}

function UserSession() {
    const [userSession, setUserSession] = useState(null);
    const { params: { userSessionId } } = useRouteMatch();
    const [url, setURL] = useState("");
    const [movement, setMovement] = useState({ "movementName": "" });
    const [targetGoals, setTargetGoals] = useState({ "setsGoals": { "setsNumber": null } });
    const [sessionState, setSessionState] = useState({ "setNumber": null });
    const [datetime, setDateTime] = useState("");
    const [duration, setDuration] = useState("");

    useEffect(() => {
        if (userSessionId) {
            const fetchData = async () => {
                return await axios.get(`${userSessionsURL}/${userSessionId}`);
            };
            fetchData()
                .then(({ data: { userSession } }) => {
                    setUserSession(userSession);
                    setMovement(userSession.movement);
                    setSessionState(userSession.session_state);
                    setTargetGoals(userSession.target_goals);
                    var date = new Date(userSession.timestamp_start);
                    setDateTime(date.toLocaleTimeString([], { weekday: "short", year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }));
                    setDuration(Math.round((userSession.timestamp_end - userSession.timestamp_start) / 1000));
                    return userSession
                })
                .then(async (retrievedUserSession) => {
                    let video = retrievedUserSession.user_video;
                    let bucket = video.bucket;
                    let key = video.key;
                    let region = "us-west-2";
                    // https://aws.amazon.com/blogs/developer/generate-presigned-url-modular-aws-sdk-javascript/
                    const s3ObjectUrl = parseUrl(`https://${bucket}.s3.${region}.amazonaws.com/${key}`);
                    const presigner = new S3RequestPresigner({
                        credentials,
                        region,
                        sha256: Hash.bind(null, "sha256"), // In Node.js
                        //sha256: Sha256 // In browsers
                    });
                    // Create a GET request from S3 url.
                    const assignedURL = await presigner.presign(new HttpRequest(s3ObjectUrl));
                    // console.log("PRESIGNED URL: ", formatUrl(assignedURL));
                    setURL(formatUrl(assignedURL));
                    // console.log({ retrievedUserSession })
                })
        }
    }, [userSessionId]);

    return (
        <section>
            <header>
                <article className="container">
                    <Table
                        color="black"
                        key="black"
                        inverted
                        size="small"
                        className="movement-table"
                    >
                        <Table.Body>
                            <Table.Row key={userSessionId}>
                                <Table.Cell>{movement.movementName}</Table.Cell>
                                <Table.Cell>{"Set: " + sessionState.setNumber + " / " + targetGoals.setsGoals.setsNumber}</Table.Cell>
                                <Table.Cell>{datetime}</Table.Cell>
                                <Table.Cell>{duration + " secs"}</Table.Cell>
                                <Table.Cell>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </article>
                <container>
                    <Fragment>
                        <Button
                            color="blue"
                            inverted
                            className="btn__submit--movement"
                            as={Link}
                            to={{ pathname: "/dashboard" }}
                        >
                            Go to Dashboard
                        </Button>

                        <Button
                            color="green"
                            inverted
                            className="btn__submit--movement"
                            as={Link}
                            to={{ pathname: "/user_sessions" }}
                        >
                            My workout videos
                        </Button>

                        <Button
                            color="pink"
                            inverted
                            className="btn__submit--movement"
                            as={Link}
                            to={{ pathname: "/sessions˝" }}
                        >
                            Try another workout
                        </Button>
                    </Fragment>
                </container>
            </header>

            <article className="container">
                <ReactPlayer
                    playing={true}
                    playsinline={true}
                    muted={true}
                    loop={true}
                    url={url}
                />
            </article>
        </section>
    )
}

export default UserSession;