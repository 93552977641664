import React, { useContext, useState, useEffect, Responsive } from "react";
import { Table, Button, Container } from "semantic-ui-react";

function SessionTable({ inputSession }) {
  return (
    <container className="container">

      <Table
        celled
      // color="black"
      // key="black"
      // inverted
      // size="small"
      // className="movement-table"
      >
        {/* <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Movement</Table.HeaderCell>
                <Table.HeaderCell>Sets#</Table.HeaderCell>
                <Table.HeaderCell>Reps#</Table.HeaderCell>
                <Table.HeaderCell>Calories</Table.HeaderCell>
                <Table.HeaderCell>Weights</Table.HeaderCell>`
                <Table.HeaderCell>Intensity</Table.HeaderCell>
              </Table.Row>
            </Table.Header> */}

        <Table.Body>

          {inputSession.sessionEntryList &&
            inputSession.sessionEntryList.map((
              { movement: { movementId, movementName },
                targetGoals: { setsGoals, repsGoals }
              }
            ) => {
              let sets = setsGoals.setsNumber ? setsGoals.setsNumber : 0;
              let reps = repsGoals.repsNumber ? repsGoals.repsNumber: 0;
              let repsText = repsGoals.repsNumber ? " x " + reps + " reps" : ""
              let timePerSet = setsGoals.timePerSet ? setsGoals.timePerSet : 0;
              let restTime = setsGoals.restTimeBetweenSets ? setsGoals.restTimeBetweenSets: 0;
              let workoutRestTimeText = "Each Set: " + timePerSet + " Secs workout and " + restTime + " Secs rest";
              let text = movementName + " || " + sets + " sets" + repsText + " || " + workoutRestTimeText;
              return (
                // TODO: fix the styling of the whole table. It's horrible.
                <Table.Row key={movementId}>
                  <Table.Cell> {text}</Table.Cell>
                  {/* <Table.Cell>{setsGoals.setsNumber}</Table.Cell>
                      <Table.Cell>{repsGoals.repsNumber}</Table.Cell> */}
                  {/* <Table.Cell>
                        <Button
                          inverted
                          color="orange"
                          className="btn--modify"
                          onClick={() => modifyHandler(movementId)}
                        >
                          Modify
                        </Button>
                        <Button
                          inverted
                          color="orange"
                          className="btn--delete"
                          // onClick={() => deleteHandler(movementId)}
                        >
                          Delete
                        </Button>
                      </Table.Cell> */}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      {/* <section>
          {movementFlowId && (
            <EditMovementSection
              movementId={movementFlowId}
              setMovementFlowId={setMovementFlowId}
            />
          )}
        </section> */}
    </container>
  );
}

export default SessionTable;
