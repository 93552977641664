
const bodyPhysicalMeasurement = {
    age: null,
    gender: null,
    weight: null,
    weight_unit: "lb",
    height: null,
    height_unit: "inch"
}

const weightGoals = {
    weight_change_goal: null,
    pace_of_change: null,
    body_fat_goal: null,
    muscle_mass_goal: null,
    target_weight: null,
    weight_unit: null,
}

const healthCalculations = {
    BMI: null,
    BMR: null,
    min_healthy_weight: null, 
    max_healthy_weight: null,
    ideal_weight: null,
    weight_unit: null,
}

const currentFitnessLevel = {
    overall: 5,
    strength: 5,
    mobility: 5,
    endurance: 5,
    // cardio: null,
    // balance: null,
    // flexibility: null,
    // agility: null,
    pain: 5
}

const targetFitnessLevel = {
    macros: 5,
    activity: 5,
    diet: 5,
    // planning: null,
    // diet: null,
    strength: 5,
    mobility: 5,
    endurance: 5,
    // cardio: null,
    // balance: null,
    flexibility: 5,
    agility: 5,
    rehab: 5
}

const painLevel = {
    neck: null,
    shoulders: null,
    wrists: null,
    hips: null,
    knees: null,
    ankles: null,
    chest: null,
    lower_back: null,
    upper_back: null
}

const mobilityLevel = {
    neck: null,
    shoulders: null,
    wrists: null,
    hips: null,
    pelvis: null,
    knees: null,
    ankles: null,
    chest: null,
    spine: null
}

const strenghtLevel = {
    legs: null,
    arms: null,
    abs: null,
    chest: null,
    back: null,
    shoulders: null
}

const user = {
    email: null,
    email_verified: null,
    family_name: null,
    given_name: null,
    locale: null,
    name: null,
    nickname: null,
    picture: null,
    sub: null,
};

export const userFitnessStateGoalInitial = {
    user: user,
    body_physical_measurement: bodyPhysicalMeasurement,
    health_calculations: healthCalculations,
    weight_goals: weightGoals,
    current_fitness_level: currentFitnessLevel,
    target_fitness_level: targetFitnessLevel,
    current_pain_level: painLevel,
    target_rehabilitation_level: painLevel,
    current_strength_level: strenghtLevel,
    target_strength_level: strenghtLevel,
    current_mobility_level: mobilityLevel,
    target_mobility_level: mobilityLevel,
    available_equipments: {gym: [], home: [], travel: []},
}