import React, { useState, useEffect } from "react";
import {
    Dropdown,
    Input,
    Select,
    Form,
} from "semantic-ui-react";
import { Popup } from "semantic-ui-react";
import { isMobile } from 'react-device-detect';

import {
    equipmentOptions,
    videoSourceOptions,
    workoutCategoryOptions,
} from "../../data/constants";

function MovementForm({ movement, setMovement, allMovements }) {
    const [allMovementsOptions, setAllMovementsOptions] = useState([])

    const getMovementOptions = (allMovements) => {
        let allMovementsOptionsArray = []
        allMovements.map(({ movementName }) => {
            allMovementsOptionsArray.push({
                key: movementName,
                value: movementName,
                text: movementName,
            });
            return true;
        });
        setAllMovementsOptions(allMovementsOptionsArray)
        console.log(allMovementsOptionsArray)
    }

    useEffect(() => {
        getMovementOptions(allMovements)
    }, [allMovements]);


    const onChangeMovementHandler = ({ target: { name, value } }) => {
        setMovement((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const dropDownMovementHandler = (_, data) => {
        if (data.name === "equipmentList") {
            setMovement((prevState) => {
                return {
                    ...prevState,
                    equipmentList: data.value,
                };
            });
        }
        if (data.name === "workoutCategoryList") {
            setMovement((prevState) => {
                return {
                    ...prevState,
                    workoutCategoryList: data.value,
                };
            });
        }
        if (data.name === "videoSource") {
            setMovement((prevState) => {
                return {
                    ...prevState,
                    videoSource: data.value,
                };
            });
        }
    };

    const searchMovement = (event, { value }) => {
        const oldMovement = allMovements.find(
            ({ movementName }) => movementName === value
        );

        const movementOLD = {
            movementName: oldMovement.movementName,
            equipmentList: oldMovement.equipmentList,
            videoEndTime: oldMovement.videoEndTime,
            videoStartTime: oldMovement.videoStartTime,
            videoSource: oldMovement.videoSource,
            videoUrl: oldMovement.videoUrl,
            workoutCategoryList: oldMovement.workoutCategoryList,
        };
        setMovement(movementOLD);
    };


    if (isMobile) {
        return (<section>
            <header>
                {"Movement"}
            </header>
            <form>
                {/* <Form.Field> */}
                <Popup
                    trigger={
                        <Select
                            placeholder="Select a movement / video from database"
                            options={allMovementsOptions}
                            onChange={searchMovement}
                            value={movement.movementName || "Select a movement / video from database"}
                            selectOnBlur={false}
                        />

                    }
                    content="Select a movement / video from database or fill the form"
                />
            </form>
        </section>)
    }
    else {
        return (

            <section>
                <section className="form-control-session">
                    <header className="movement">
                        {"Movement"}
                    </header>
                    <form className="form--movement--control">
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Select
                                    placeholder="Select a movement / video from database"
                                    options={allMovementsOptions}
                                    onChange={searchMovement}
                                    value="Select a movement / video from database"
                                    selectOnBlur={false}
                                />

                            }
                            content="Select a movement / video from database or fill the form"
                        />
                        {/* </Form.Field> */}

                        {/* <Form.Field> */}
                        <Input
                            list="movementList"
                            placeholder="Movement Name"
                            className="movement--display--name"
                            name="movementName"
                            onChange={onChangeMovementHandler}
                            value={movement.movementName || ""}
                            multiple={false}
                            selectOnBlur={false}
                        />

                        {/* </Form.Field> */}
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Input
                                    placeholder="Video URL"
                                    className="movement--video"
                                    type="url"
                                    name="videoUrl"
                                    onChange={onChangeMovementHandler}
                                    value={movement.videoUrl || ""}
                                />
                            }
                            content="Video URL"
                        />
                        {/* </Form.Field> */}
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Input
                                    placeholder="Video Start Time"
                                    name="videoStartTime"
                                    onChange={onChangeMovementHandler}
                                    className="movement--start"
                                    value={movement.videoStartTime || ""}
                                />
                            }
                            content="Video Start Time"
                        />
                        {/* </Form.Field> */}
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Input
                                    placeholder="Video End Time"
                                    name="videoEndTime"
                                    onChange={onChangeMovementHandler}
                                    className="movement--end"
                                    value={movement.videoEndTime || ""}
                                />
                            }
                            content="Video End Time"
                        />
                        {/* </Form.Field> */}
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Select
                                    placeholder="Video Source"
                                    options={videoSourceOptions}
                                    className="equipment-select"
                                    name="videoSource"
                                    onChange={dropDownMovementHandler}
                                    multiple={false}
                                    selectOnBlur={false}
                                    value={movement.videoSource || ""}
                                />
                            }
                            content="Video Source"
                        />
                        {/* </Form.Field> */}
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Dropdown
                                    placeholder="Equipments"
                                    multiple
                                    selection
                                    options={equipmentOptions}
                                    className="equipment-select"
                                    openOnFocus
                                    name="equipmentList"
                                    onChange={dropDownMovementHandler}
                                    value={
                                        (movement.equipmentList &&
                                            movement.equipmentList.map((items) => items)) ||
                                        []
                                    }
                                />
                            }
                            content="Equipments"
                        />
                        {/* </Form.Field> */}
                        {/* <Form.Field> */}
                        <Popup
                            trigger={
                                <Dropdown
                                    placeholder="Workout Categories"
                                    multiple
                                    selection
                                    options={workoutCategoryOptions}
                                    className="workout-category"
                                    openOnFocus
                                    name="workoutCategoryList"
                                    onChange={dropDownMovementHandler}
                                    value={
                                        (movement.workoutCategoryList &&
                                            movement.workoutCategoryList.map((items) => items)) ||
                                        []
                                    }
                                />
                            }
                            content="Workout Categories"
                        />
                        {/* </Form.Field> */}
                        {/* </Form> */}
                    </form>
                </section>
            </section>
        );
    }
}

export default MovementForm;
