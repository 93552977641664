import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "react-bootstrap";

function Auth0() {
    const { isAuthenticated, user } = useAuth0();
    console.log({ user });
    return (
        <>
            {isAuthenticated && (
                <>
                    <h1>USER</h1>
                    <br></br>
                    <p>Email: {user.email}</p>
                    <p>Nickname: {user.nickname}</p>
                    <p>Name: {user.name}</p>
                    <p>ID: {user.sub}</p>
                </>
            )
            }
        </>
    )
}

export default Auth0;
