import React from "react";
import Webcam from "react-webcam"; 
import { Button } from "semantic-ui-react";
import AWS from "aws-sdk"
import { isMobile, browserName } from 'react-device-detect';
var mimeType = "video/mp4"
if (browserName === "Chrome" && !isMobile) {
  // console.log("chrome in desktop");
  mimeType = "video/webm"
  
}

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;
var s3 = new AWS.S3({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region
});

const WebcamStreamCapture = () => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: mimeType
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, []);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.mp4";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);
  
  const handleUpload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4"
      });
      console.log(blob);
      console.log(s3);
      const url = URL.createObjectURL(blob);
      let key = Date.now().toString() + ".mp4"
      s3.putObject({
        Key: key,
        Body: blob,
        Bucket: "workout-vision-public",
        'ContentType': 'video/mp4',
        ACL: 'public-read'
        }, (err) => {
            if(err){
              console.log("ERROR: Upload to S3 was not succesful"); 
              console.log(err);
            } else {
              console.log("Upload to S3 was successful");
            }
        }
    )
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);


  const videoConstraints = {
    facingMode: "environment"
  };

  return (
    <>
      <Webcam audio={false} ref={webcamRef} videoConstraints={videoConstraints} />
      <section>
      {capturing ? (
        <Button onClick={handleStopCaptureClick}>Stop Capture</Button>
      ) : (
        <Button onClick={handleStartCaptureClick}>Start Capture</Button>                       
      )}
      {recordedChunks.length > 0 && (
        <Button onClick={handleDownload}>Download</Button>
      )}
      {recordedChunks.length > 0 && (
        <Button onClick={handleUpload}>Upload</Button>
      )}
      </section>
    </>
  );
};

export default WebcamStreamCapture;