import React, { Fragment } from "react";
import Container from '@mui/material/Container';
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import Button from '@mui/material/Button';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const NODE_ENV = process.env.NODE_ENV;
const NODE_ENV_PROD = (NODE_ENV === "production");
if (!NODE_ENV_PROD) {
  const dotenv = require("dotenv");
  dotenv.config();
}

function Home() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const history = useHistory();

  function handleDashboardClick() {
    history.push("/dashboard");
  }
  // if (!isAuthenticated) {
  return (
    <>
        <CssBaseline />
        
        <Container>
          <Box sx={{ width: '90%', height: '100%', maxWidth: 500, marginTop: 2, marginLeft: 2 }}>
            <Typography variant="h5" component="div" gutterBottom>
              your personal training team
            </Typography>

            <br></br>
            <Typography variant="body1" component="div" >
              We are a team of fitness experts and personal trainers. 
              We want to help you reach your fitness goals and hit your target weight. 
  
              <br></br>
              <br></br>
              We recommend you personal workout and diet plans, guide your workout, 
              analyze your activity and diet actions, and provide insights on your 
              performance and progress towards your target goals.

            </Typography>
            <br></br>
          <br></br>
            <Button variant="outlined" size="large" style={{ color: "violet" }} onClick={() => loginWithRedirect()}>Start Your Fitness Journey</Button>
          </Box>
          

        </Container>
    </>
  );
}

export default Home;
