import React, { Fragment } from "react";
import { Button, Table } from "semantic-ui-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const sessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_SESSIONS_SUFFIX;
const userSessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_USER_SESSIONS_SUFFIX;
const sessionsURL = serverURL + sessionsSuffix;
const userSessionsURL = serverURL + userSessionsSuffix;

function AllUserSessions() {
  const queryClient = useQueryClient();
  const { isLoading, isError, data } = useQuery(
    ["allUserSessionsQuery"],
    async () => {
      return await (
        await axios.get(userSessionsURL)
      ).data;
    }
  );

  const { mutate, isSuccess } = useMutation(async (userSessionId) => {
    await axios.delete(`${userSessionsURL}/${userSessionId}`);
  });

  const deleteHandler = (userSessionId) => {
    mutate(userSessionId);
  };

  if (isSuccess) {
    queryClient.invalidateQueries("allUserSessionsQuery");
  }
  if (isLoading) {
    return <h1>Loading . . . </h1>;
  }
  if (isError) {
    return <h1>Error . . . </h1>;
  }

  return (
    <section>
      <header>
        <h1>Your Workout Videos</h1>
        <container>
          <Fragment>
            <Button
              color="blue"
              inverted
              className="btn__submit--movement"
              as={Link}
              to={{ pathname: "/dashboard" }}
            >
              Go to Dashboard
            </Button>

            <Button
              color="green"
              inverted
              className="btn__submit--movement"
              as={Link}
              to={{ pathname: "/sessions" }}
            >
              Try another workout session
            </Button>

            <Button
              color="pink"
              inverted
              className="btn__submit--movement"
              as={Link}
              to={{ pathname: "/session" }}
            >
              Create your own workout session
            </Button>
          </Fragment>
        </container>

      </header>
      <article className="container">
        <Table
          color="black"
          key="black"
          inverted
          size="small"
          className="movement-table"
        >
          {/* <Table.Header>
          <Table.Row key={0}>
                    <Table.Cell>{"Movement"}</Table.Cell>
                    <Table.Cell>{"Set / Sets#"}</Table.Cell>
                    <Table.Cell>{"Workout Time"}</Table.Cell>
                    <Table.Cell>{"Duration"}</Table.Cell>
                    <Table.Cell>{"Modify Button"}</Table.Cell>
                    <Table.Cell>{"Delete Button"}</Table.Cell>
                    <Table.Cell>{"Launch Button"}</Table.Cell>
          </Table.Row>
          </Table.Header> */}
          <Table.Body>
            {data &&
              data.allUserSessions.map(({ _id: userSessionId, movement, session_state, target_goals, timestamp_start, timestamp_end, user_video_id }) => {
                var date = new Date(timestamp_start);
                // var formatted = date.toDateString() + " -- " + date.toLocaleTimeString();
                var formatted = date.toLocaleTimeString([], { weekday: "short", year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
                var duration = Math.round((timestamp_end - timestamp_start) / 1000);
                // var d = new Date(1469433907836);
                // d.toLocaleString(); // expected output: "7/25/2016, 1:35:07 PM"
                // d.toLocaleDateString(); // expected output: "7/25/2016"
                // d.toDateString();  // expected output: "Mon Jul 25 2016"
                // d.toTimeString(); // expected output: "13:35:07 GMT+0530 (India Standard Time)"
                // d.toLocaleTimeString(); // expected output: "1:35:07 PM"
                var setsNumber = 1;
                if (typeof target_goals.setsGoals != "undefined") {
                  setsNumber = target_goals.setsGoals.setsNumber;
                }

                return (
                  <Table.Row key={userSessionId}>
                    <Table.Cell>{movement.movementName}</Table.Cell>
                    <Table.Cell>{"Set: " + session_state.setNumber + " / " + setsNumber}</Table.Cell>
                    <Table.Cell>{formatted}</Table.Cell>
                    <Table.Cell>{duration + " secs"}</Table.Cell>
                    <Table.Cell>
                      <Button
                        inverted
                        color="orange"
                        className="btn--modify"
                        as={Link}
                        to={{ pathname: `/user_session/${userSessionId}` }}
                      >
                        WATCH VIDEO
                      </Button>
                      {/* <Button
                        inverted
                        color="red"
                        className="btn--delete"
                        onClick={() => deleteHandler(userSessionId)}
                      >
                        DELETE 
                      </Button> */}
                      <Button
                        inverted
                        color="yellow"
                        className="btn--delete"
                        as={Link}
                        to={{ pathname: `/user_visualization/${user_video_id}` }}
                      >
                        VIEW ANALYSIS
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </article>
    </section>
  );
}

export default AllUserSessions;
