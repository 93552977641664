import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import 'semantic-ui-css/semantic.min.css' // delete this

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';

import Home from "./components/Home/Home";
import Movement from "./components/Movement/Movement";
import AllMovements from "./components/Movement/AllMovements";
import WebcamStreamCapture from "./components/Video/WebcamStreamCapture"
import Session from "./components/Session/Session";
import AllSessions from "./components/Session/AllSessions";
import AllUserSessions from "./components/UserSession/AllUserSessions";
import AllUserSessionsByUserId from "./components/UserSession/AllUserSessionsByUserId";
import UserSession from "./components/UserSession/UserSession";
import UserVisualization from "./components/UserSession/UserVisualization";
import Looper from "./components/Looper/Looper";
import Player from "./components/Looper/Player";
import Dashboard from "./components/Dashboard/Dashboard";
import WorkoutRecommendations from "./components/Recommendations/WorkoutRecommendations";
import UserFitnessStateGoal from "./components/UserFitnessStateGoal/UserFitnessStateGoal";
import Auth0 from "./components/Auth0/Auth0";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from '@auth0/auth0-react';
import { ToastContainer } from "react-toastify";
import { useSearch } from "rsuite/esm/Picker";

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });
const NODE_ENV = process.env.NODE_ENV;
const NODE_ENV_PROD = (NODE_ENV === "production");
if (!NODE_ENV_PROD) {
  const dotenv = require("dotenv");
  dotenv.config();
}

const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

const client = new QueryClient();



const appView = (
  <>
    <Router>
      <Switch>
        <Route path="/" exact component={() => <Home />} />
        <Route path="/movement" exact component={() => <Movement />} />
        <Route path="/movements" exact component={() => <AllMovements />} />
        <Route path="/movement/:movementId" component={() => <Movement />} />
        <Route path="/session" exact component={() => <Session />} />
        <Route path="/session/:sessionId" component={() => <Session />} />
        <Route path="/sessions" exact component={() => <AllSessions />} />
        <Route path="/user_session/:userSessionId" component={() => <UserSession />} />
        <Route path="/user_visualization/:userVideoId" component={() => <UserVisualization />} />
        <Route path="/user_sessions_dev" exact component={() => <AllUserSessions />} />
        <Route path="/user_sessions/user_id/:userId" component={() => <AllUserSessionsByUserId />} />
        <Route path="/stream" exact component={() => <WebcamStreamCapture />} />
        <Route path="/looper" exact component={() => <Looper />} />
        <Route path="/looper/:sessionId" component={() => <Looper />} />
        <Route path="/player" exact component={() => <Player />} />
        <Route path="/player/:sessionId" component={() => <Player />} />
        <Route path="/dashboard" exact component={() => <Dashboard />} />
        <Route path="/auth0" exact component={() => <Auth0 />} />
        <Route path="/user_fitness_state_goal" exact component={() => <UserFitnessStateGoal />} />
        <Route path="/user_fitness_state_goal/:userFitnessStateGoalId" component={() => <UserFitnessStateGoal />} />
        <Route path="/workout_recommendations" exact component={() => <WorkoutRecommendations />} />
      </Switch>
    </Router>
    <ToastContainer limit={2} autoClose={3000} />
  </>
);

function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light');
  const [theme, setTheme] = React.useState(
    createTheme({
      palette: {
        mode: mode,
      },
    }));

  const changeMode = () => {
    return setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  }

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light')
  }, [prefersDarkMode]);

  useEffect(() => {
    setTheme(createTheme({
      palette: {
        mode: mode,
      },
    }))
  }, [mode]);

  const optimAppBar = (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            OPTIM.FIT
            <IconButton sx={{ ml: 1 }} onClick={changeMode} color="inherit">
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          </Typography>
          {/* <Button color="inherit">Login / Signup</Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin + '/dashboard'}>
          <QueryClientProvider client={client}>
            {/* {optimAppBar} */}
            {appView}
          </QueryClientProvider>
        </Auth0Provider>
    </ThemeProvider>

  );
}

export default App;
