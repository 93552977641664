import { object, string, array, number, boolean } from "yup";

export const podFormValidation = object().shape({
  podName: string()
    .ensure()
    .required("podName is required")
    .min(2, "Minimum podName length is 2 letters"),
  workoutCategoryList: array().ensure().of(string()).nullable().optional(),
  podStartResetTime: number().nullable().optional(),
  podStartPause: boolean().default(false).nullable(),
  showDemoVideo: boolean().default(true).optional(),
  videRecording: boolean().default(true).optional(),
  comment: string().nullable().optional(),
});

export const roundFormValidation = object().shape({
  roundGoal: object().shape({
    roundNumber: number()
      .default(1)
      .positive("Enter a Positive time.")
      .optional(),
    roundGoalType: string().optional().nullable(),
  }),
  timeGoal: object().shape({
    timeGoalType: string().optional().nullable(),
    time: string()
      // .matches(/^[+]?[0-9]+\.?[0-9]+$/, "Time must be of number")
      .optional()
      .nullable(),
    timeUnit: string().default("seconds").optional(),
  }),
  showDemoVideo: boolean().optional().nullable(),
  restTimeBetweenRounds: number().default(0).optional(),
  comment: string().optional().nullable(),
});

export const movementFormValidation = object().shape({
  movementName: string().ensure().nullable().optional(),
  videoUrl: string().ensure().nullable().optional(),
  videoStartTime: number().nullable().optional(),
  // .matches(/^[+]?[0-9]+\.?[0-9]+$/, "Time must be of number"),
  videoEndTime: number().nullable().optional(),
  equipmentList: array().of(string()).nullable().optional(),
  videoSource: string().ensure().nullable().optional(),
  workoutCategoryList: array().of(string()).nullable().optional(),
});
