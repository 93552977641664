
import React, { useEffect } from "react";

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';

const units = {
    weight_unit: {
        us_units: "lb",
        metric_units: "kg"
    },
    height_unit: {
        us_units: "inch",
        metric_units: "cm"
    }

}

function WeightGoals({ userFitnessStateGoal, setUserFitnessStateGoal, units, unit, setUnit }) {
    const handleUnitChange = (event, newValue) => {
        setUnit(newValue);
    };

    const calculte = () => {
        let currentWeight = userFitnessStateGoal.body_physical_measurement.weight;
        let currentHeight = userFitnessStateGoal.body_physical_measurement.height;
        let age = userFitnessStateGoal.body_physical_measurement.age;
        let minBMI = 18.5;
        let maxBMI = 25;
        if (userFitnessStateGoal.body_physical_measurement.weight_unit === "lb") {
            currentWeight = currentWeight / 2.20462; // convert lb to kg
        }
        if (userFitnessStateGoal.body_physical_measurement.height_unit === "inch") {
            currentHeight = currentHeight * 0.0254; // convert inch to meter
        }
        else {
            currentHeight = currentHeight * 0.01; // convert cm to meter
        }
        let BMI = currentWeight / Math.pow(currentHeight, 2);
        let BMR = null;
        // console.log(BMI);
        let minWeight = Math.floor(minBMI * Math.pow(currentHeight, 2)); // kg
        let maxWeight = Math.floor(maxBMI * Math.pow(currentHeight, 2)); // kg

        if (userFitnessStateGoal.body_physical_measurement.weight_unit === "lb") {
            minWeight = Math.floor(minBMI * Math.pow(currentHeight, 2) * 2.20462);
            maxWeight = Math.floor(maxBMI * Math.pow(currentHeight, 2) * 2.20462);
        }

        // D. R. Miller Formula (1983)
        // Male:	56.2 kg + 1.41 kg per inch over 5 feet
        // Female:	53.1 kg + 1.36 kg per inch over 5 feet

        // J. D. Robinson Formula (1983)
        // Male:	52 kg + 1.9 kg per inch over 5 feet
        // Female:	49 kg + 1.7 kg per inch over 5 feet

        let MillerIdealWeight = null;
        let RobinsonIdealWeight = null;
        let idealWeight = null;

        let currentHeightInch = currentHeight * 39.3701 // convert m to inches
        if (userFitnessStateGoal.body_physical_measurement.gender === "male") {

            MillerIdealWeight = 56.2 + 1.41 * (currentHeightInch - 60) // kg
            RobinsonIdealWeight = 52 + 1.9 * (currentHeightInch - 60) // kg
        }
        else { // female
            MillerIdealWeight = 53.1 + 1.36 * (currentHeightInch - 60) // kg
            RobinsonIdealWeight = 549 + 1.7 * (currentHeightInch - 60) // kg
        }

        idealWeight = Math.floor((MillerIdealWeight + RobinsonIdealWeight) / 2); // kg
        if (userFitnessStateGoal.body_physical_measurement.weight_unit === "lb") {
            idealWeight = Math.floor(idealWeight * 2.20462);
        }

        let defaultWeightGoal = null;
        if (userFitnessStateGoal.body_physical_measurement.weight < idealWeight) {
            defaultWeightGoal = "gain_weight";
        } else if (userFitnessStateGoal.body_physical_measurement.weight > idealWeight) {
            defaultWeightGoal = "lose_weight";
        } else {
            defaultWeightGoal = "maintain_weight";
        }

        // Mifflin-St Jeor Equation:
            // For men: BMR = 10W + 6.25H - 5A + 5
            // For women: BMR = 10W + 6.25H - 5A - 161
        // Revised Harris-Benedict Equation:
            // For men: BMR = 13.397W + 4.799H - 5.677A + 88.362
            // For women: BMR = 9.247W + 3.098H - 4.330A + 447.593

        let MifflinBMR = null;
        let HarrisBendeictBMR = null;

        if (userFitnessStateGoal.body_physical_measurement.gender === "male") {

            MifflinBMR = 10 * currentWeight + 6.25 * 100 * currentHeight - 5 * age + 5
            HarrisBendeictBMR = 13.397 * currentWeight + 4.7999 * 100 * currentHeight - 5.677 * age + 88.362
        }
        else { // female
            MifflinBMR = 10 * currentWeight + 6.25 * 100 * currentHeight - 5 * age - 161
            HarrisBendeictBMR = 9.247 * currentWeight + 3.098 * 100 * currentHeight - 4.33 * age + 447.593
        }

        BMR = Math.floor((MifflinBMR + HarrisBendeictBMR) / 2);

        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                health_calculations: {
                    BMI: BMI,
                    BMR: BMR,
                    min_healthy_weight: minWeight,
                    max_healthy_weight: maxWeight,
                    ideal_weight: idealWeight
                },
            };
        });

        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                weight_goals: { ...prevState.weight_goals, weight_change_goal: defaultWeightGoal, target_weight: idealWeight },
            };
        });
    }

    useEffect(() => {
        if (userFitnessStateGoal.body_physical_measurement.weight !== null & 
            userFitnessStateGoal.body_physical_measurement.height !== null & 
            userFitnessStateGoal.body_physical_measurement.age !== null &
            userFitnessStateGoal.body_physical_measurement.gender !== null) {
        calculte();
        }
    }, []);

    useEffect(() => {
        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                weight_goals: { ...prevState.weight_goals, weight_unit: units.weight_unit[unit] },
            };
        });
    }, [unit]);

    const onChangeHandler = ({ target: { name, value } }) => {
        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                weight_goals: { ...prevState.weight_goals, [name]: value },
            };
        });
    };

    return (
        <Box sx={{
            width: '100%', '& > :not(style)':
                { m: 2, width: '35ch' }
        }}
            component="form"
            noValidate
            autoComplete="off"
        >
            <Tabs value={unit} onChange={handleUnitChange} >
                <Tab label="US Units" value="us_units" />
                <Tab label="Metric Units" value="metric_units" />
            </Tabs>

            <FormControl sx={{ m: 2, width: '100%' }} variant="outlined">
                <TextField
                    id="outlined-basic"
                    label="Healthy Weight Range"
                    variant="outlined"
                    value={"from " + userFitnessStateGoal.health_calculations.min_healthy_weight + " to " + userFitnessStateGoal.health_calculations.max_healthy_weight}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{units.weight_unit[unit]}</InputAdornment>
                    }}
                />
                <br></br>

                <TextField
                    id="outlined-basic"
                    label="Your Current Weight"
                    value={userFitnessStateGoal.body_physical_measurement.weight}
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{units.weight_unit[unit]}</InputAdornment>
                    }}
                />
                <br></br>

                <TextField
                    id="outlined-basic"
                    label="Set Your Target Weight"
                    variant="outlined"
                    name="target_weight"
                    value={userFitnessStateGoal.weight_goals.target_weight}
                    onChange={onChangeHandler}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{units.weight_unit[unit]}</InputAdornment>
                    }}
                />
                <br></br>
                <FormLabel id="demo-radio-buttons-group-label">Weight Goals</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="weight_change_goal"
                    value={userFitnessStateGoal.weight_goals.weight_change_goal}
                    onChange={onChangeHandler}
                >
                    <FormControlLabel value="lose_weight" control={<Radio />} label="Lose" />
                    <FormControlLabel value="maintain_weight" control={<Radio />} label="Maintain" />
                    <FormControlLabel value="gain_weight" control={<Radio />} label="Gain" />

                </RadioGroup>
                <br></br>

                <FormLabel id="demo-radio-buttons-group-label">Pace of Change</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="pace_of_change"
                    value={userFitnessStateGoal.weight_goals.pace_of_change}
                    onChange={onChangeHandler}
                >
                    <FormControlLabel value="mild" control={<Radio />} label="Mild" />
                    <FormControlLabel value="moderate" control={<Radio />} label="Moderate" />
                    <FormControlLabel value="extreme" control={<Radio />} label="Extreme" />
                </RadioGroup>
                <br></br>
                <FormLabel id="demo-radio-buttons-group-label">Body Fat %</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="body_fat_goal"
                    value={userFitnessStateGoal.weight_goals.body_fat_goal}
                    onChange={onChangeHandler}
                >
                    <FormControlLabel value="lose_body_fat" control={<Radio />} label="Lose" />
                    <FormControlLabel value="maintain_body_fat" control={<Radio />} label="Maintain" />
                    <FormControlLabel value="gain_body_fat" control={<Radio />} label="Gain" />
                </RadioGroup>
                {/* <br></br> */}
                {/* <FormLabel id="demo-radio-buttons-group-label">Muscle Mass %</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="muscle_mass_goal"
                    value={userFitnessStateGoal.weight_goals.muscle_mass_goal}
                    onChange={onChangeHandler}
                >
                    <FormControlLabel disabled value="lose_muscle_mass" control={<Radio />} label="Lose" />
                    <FormControlLabel value="maintain_muscle_mass" control={<Radio />} label="Maintain" />
                    <FormControlLabel value="gain_muscle_mass" control={<Radio />} label="Gain" />
                </RadioGroup> */}

            </FormControl>
        </Box>
    );
}

export default WeightGoals;