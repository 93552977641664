import { Button, Table } from "semantic-ui-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

// FILES:   _______  CSS Files _______
// import "semantic-ui-css/semantic.min.css";

// import "./allSessions.css";
import { Link } from "react-router-dom";

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const sessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_SESSIONS_SUFFIX;
const sessionsURL = serverURL + sessionsSuffix;

function AllSessions() {
  const queryClient = useQueryClient();
  const { isLoading, isError, data } = useQuery(
    ["allSessionsQuery"],
    async () => {
      return await (
        await axios.get(sessionsURL)
      ).data;
    }
  );

  const { mutate, isSuccess } = useMutation(async (sessionId) => {
    await axios.delete(`${sessionsURL}/${sessionId}`);
  });

  const deleteHandler = (sessionId) => {
    mutate(sessionId);
  };

  if (isSuccess) {
    queryClient.invalidateQueries("allSessionsQuery");
  }
  if (isLoading) {
    return <h1>Loading . . . </h1>;
  }
  if (isError) {
    return <h1>Error . . . </h1>;
  }

  return (
    <section>
      <header>
        <h1>Designed Workout Sessions</h1>
        <Button
          color="blue"
          inverted
          className="btn--add--data"
          as={Link}
          to={{ pathname: "/session" }}
        >
          Create a Custum Workout Session
        </Button>
      </header>
      <article className="container">
        <Table
          color="black"
          key="black"
          inverted
          size="small"
          className="movement-table"
        >
          <Table.Body>
            {data &&
              data.allSessions.map(({ _id: sessionId, sessionMetadata }) => {
                return (
                  <Table.Row key={sessionId}>
                    <Table.Cell>{sessionMetadata.sessionName}</Table.Cell>
                    <Table.Cell>
                      <Button
                        inverted
                        color="orange"
                        className="btn"
                        as={Link}
                        to={{ pathname: `/session/${sessionId}` }}
                      >
                        VIEW AND MODIFY
                      </Button>
                      {/* <Button
                        inverted
                        color="red"
                        className="btn--delete"
                        onClick={() => deleteHandler(sessionId)}
                      >
                        DELETE
                      </Button> */}
                      <Button
                        inverted
                        color="yellow"
                        className="btn"
                        as={Link}
                        to={{ pathname: `/looper/${sessionId}` }}
                      >
                        LAUNCH
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </article>
    </section>
  );
}

export default AllSessions;
