import React from "react";
import {
    Dropdown,
    Input,
    Popup,
} from "semantic-ui-react";
import {
    workoutCategoryOptions,
} from "../../data/constants";

function SessionMetaData({ sessionMetadata, setSessionMetadata }) {

    const onChangeStringHandler = ({ target: { name, value } }) => {
        setSessionMetadata((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const dropDownWorkoutCategoryListHandler = (_, data) => {
        setSessionMetadata((prevState) => {
            return {
                ...prevState,
                workoutCategoryList: data.value,
            };
        });
    };

    return (
        <container className="container">
            {/* <header className="movement">Session Metadata</header> */}
            <form autoComplete="on" className="form--movement--control">
                <Popup
                    trigger={
                        <Input
                            placeholder="Session Name"
                            className="equipment-select"
                            type="text"
                            name="sessionName"
                            onChange={onChangeStringHandler}
                            value={sessionMetadata.sessionName || ""}
                        />
                    }
                    content="Session Name"
                />

                <Popup
                    trigger={
                        <Dropdown
                            placeholder="Workout Categories"
                            multiple
                            selection
                            options={workoutCategoryOptions}
                            className="workout-category"
                            openOnFocus
                            name="workoutCategoryList"
                            onChange={dropDownWorkoutCategoryListHandler}
                            value={
                                (sessionMetadata.workoutCategoryList &&
                                    sessionMetadata.workoutCategoryList.map((items) => items)) ||
                                []
                            }
                        />
                    }
                    content="Workout Categories"
                />
            </form>
        </container >

    )
}

export default SessionMetaData;


