import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";

export default function Buttons({ buttonState, setButtonState }) {

  const podHandler = () => {
    setButtonState((prevState) => {
      return {
        ...prevState,
        pod: !prevState.pod,
      };
    });
  }

  const roundHandler = () => {
    setButtonState((prevState) => {
      return {
        ...prevState,
        round: !prevState.round,
      };
    });
  }

  const movementHandler = () => {
    setButtonState((prevState) => {
      return {
        ...prevState,
        movement: true,
      };
    });
  }


  const videoHandler = () => {
    setButtonState((prevState) => {
      return {
        ...prevState,
        video: true,
      };
    });
  };

  return (
    <Fragment>
      {/* <Button inverted color="orange" onClick={podHandler}>
        {buttonState.pod ? "Exit the Pod " : "Add a Pod"}
      </Button> */}

      {/* <Button inverted color="orange" onClick={roundHandler}>
        {buttonState.round ? "Exit the Round " : "Add a Round"}
      </Button> */}

      <Button inverted color="orange" onClick={movementHandler}>
        Add a Movement and Target Goals
      </Button>

      {/* <Button inverted color="orange" onClick={videoHandler}>
        Add a Video
      </Button> */}

    </Fragment>
  );
}
