// LIBRARIES:
import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import {
  Dropdown,
  Button,
  Input,
  Icon,
  Select,
  Dimmer,
  Header,
  Popup,
} from "semantic-ui-react";

import axios from "axios";
import {
  videoSourceOptions,
  equipmentOptions,
  workoutCategoryOptions,
} from "../../data/constants";
import ReactPlayer from 'react-player'
import { Container } from "react-bootstrap";
require("dotenv").config();

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const movementsSuffix = process.env.REACT_APP_EXPRESS_SERVER_MOVEMENTS_SUFFIX;
const movementsURL = serverURL + movementsSuffix;

function Movement() {
  const {
    params: { movementId },
  } = useRouteMatch();

  const [movementState, setMovementStates] = useState({
    movementName: "",
    videoUrl: "",
    videoStartTime: "",
    videoEndTime: "",
    equipmentList: [],
    videoSource: "",
    workoutCategoryList: [],
  });
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await axios({
        url: movementsURL,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: movementState,
      });
      setIsSuccess(true);
    } catch ({
      response: {
        data: { error },
      },
    }) {
      setOpen(true);
      setErrorMsg(error.message);
    }
  };

  const onChangeHandler = ({ target: { name, value } }) => {
    setMovementStates((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const dropDownChange = (_, data) => {
    if (data.name === "equipmentList") {
      setMovementStates((prevState) => {
        return {
          ...prevState,
          equipmentList: data.value,
        };
      });
    }
    if (data.name === "workoutCategoryList") {
      setMovementStates((prevState) => {
        return {
          ...prevState,
          workoutCategoryList: data.value,
        };
      });
    }
    if (data.name === "videoSource") {
      setMovementStates((prevState) => {
        return {
          ...prevState,
          videoSource: data.value,
        };
      });
    }
  };

  // Edit Section  | Information

  const onEditHandler = async (e) => {
    e.preventDefault();
    try {
      await axios({
        url: movementsURL,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          movement: movementState,
          movementId,
        },
      });
      setIsSuccess(true);
    } catch ({
      response: {
        data: { error },
      },
    }) {
      setOpen(true);
      setErrorMsg(error.message);
    }
  };

  const onEditHandlerNOTHING = async (e) => {
    e.preventDefault();
    try {
    } catch ({
    }) {
    }
  };


  useEffect(() => {
    if (movementId) {
      const fetchData = async () => {
        return await axios.get(`${movementsURL}/${movementId}`);
      };
      fetchData().then(({ data: { movement } }) => {
        setMovementStates(movement);
      });
    }
  }, [movementId]);

  var eventType = movementId ? onEditHandlerNOTHING : onSubmitHandler;
  var buttonText = movementId ? "SAVE" : "SUBMIT";
  var headerText = movementId
    ? "Edit and Save the Movement Data"
    : "Add a movement and demo video data";
  var movementForm = (
    <div className="form--movement">
      <form className="form--movement--control" onSubmit={eventType}>
        <Popup
          trigger={
            <Input
              placeholder="Movement Name"
              className="movement--name"
              name="movementName"
              value={movementState.movementName}
              onChange={onChangeHandler}
            />
          }
          content="Movement Name"
        />
        <Popup
          trigger={
            <Input
              placeholder="Demo Video URL"
              className="movement--video"
              type="url"
              name="videoUrl"
              onChange={onChangeHandler}
              value={movementState.videoUrl}
            />
          }
          content="Demo Video URL"
        />
        <Popup
          trigger={
            <Input
              placeholder="Video Start Time"
              name="videoStartTime"
              onChange={onChangeHandler}
              className="movement--start"
              value={movementState.videoStartTime}
            />
          }
          content="Video Start Time"
        />
        <Popup
          trigger={
            <Input
              placeholder="Video End Time"
              name="videoEndTime"
              onChange={onChangeHandler}
              className="movement--end"
              value={movementState.videoEndTime}
            />
          }
          content="Vide End Time"
        />
        <Popup
          trigger={
            <Select
              placeholder="Source of the Video"
              options={videoSourceOptions}
              multiple={false}
              className="movement--social--media"
              name="videoSource"
              onChange={dropDownChange}
              value={movementState.videoSource}
              selectOnBlur={false}
            />
          }
          content="Source of the Video"
        />
        <Popup
          trigger={
            <Dropdown
              placeholder="Required Equipments"
              multiple
              selection
              options={equipmentOptions}
              className="equipment-select"
              openOnFocus
              name="equipmentList"
              onChange={dropDownChange}
              value={movementState.equipmentList.map((item) => item)}
            />
          }
          content="Required Equipments"
        />
        <Popup
          trigger={
            <Dropdown
              placeholder="Workout Categories of the Movement"
              multiple
              selection
              options={workoutCategoryOptions}
              className="workout-category"
              openOnFocus
              name="workoutCategoryList"
              onChange={dropDownChange}
              value={movementState.workoutCategoryList.map((item) => item)}
            />
          }
          content="Workout Categories of the Movement"
        />
        <div>
          <Button
            as="button"
            animated
            color="orange"
            className="btn--submit"
            inverted
            fluid
            type="submit"
          >
            <Button.Content visible>{buttonText}</Button.Content>
            <Button.Content hidden>
              <Icon name="arrow right" />
            </Button.Content>
          </Button>
        </div>

        <div>
          <Button
            color="blue"
            className="btn--submit"
            inverted
            fluid
            as={Link}
            to={{ pathname: "/movements" }}
          >
            ALL MOVEMENTS
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <section>
      <header>
        <h1>{headerText}</h1>
      </header>
      {movementForm}
      <Container class="container">
        <ReactPlayer
          playing={true}
          muted={true}
          loop={true}
          playsinline={true}
          url={movementState.videoUrl}
          config={{
            youtube: {
              playerVars: {
                start: movementState.videoStartTime,
                end: movementState.videoEndTime
              }
            }
          }}
        />
      </Container>
      {open && (
        <Dimmer active={open} onClickOutside={() => setOpen(false)} page>
          <Header as="h1" icon inverted>
            <Icon name="eject" />
            Error found in your fields
            <Header.Subheader>
              <h2>{errorMsg}</h2>
            </Header.Subheader>
          </Header>
        </Dimmer>
      )}

      {isSuccess && (
        <Dimmer
          active={isSuccess}
          onClickOutside={() => setIsSuccess(false)}
          page
        >
          <Header as="h1" icon inverted>
            <Icon name="diamond" />
            Done
            <Header.Subheader>Data has been sent successfully</Header.Subheader>
          </Header>
        </Dimmer>
      )}
    </section>
  );
}

export default Movement;
