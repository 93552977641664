
import React, { useState } from "react";
import { getOrientation, getWidth, getHeight } from "./Device"
import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Card, CardMedia, Box, Typography, IconButton } from '@mui/material';
import Countdown from 'react-countdown';
import ReactCountdownClock from "react-countdown-clock"
import Webcam from "react-webcam";
import logo from './logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';

const video = {
    // url: "https://youtube.com/shorts/F2wXu7r3zys", https://www.youtube.com/embed/F2wXu7r3zys
    // url: "https://www.youtube.com/embed/F2wXu7r3zys",
    url: "https://youtube.com/embed/JuDRZaB7EV8",
    start_time: null,
    end_time: null,
}

function Player() {
    const [width, setWidth] = useState(getWidth());
    const [height, setHeight] = useState(getHeight());
    const [orientation, setOrientation] = useState(getOrientation());
    const [timer, setTimer] = useState("00:00")

    return (

        <>
            {/* <iframe width={width} height={height} src="https://www.youtube.com/embed/F2wXu7r3zys?playsinline=1?&autoplay=1&mute=1&enablejsapi=1&loop=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

            <Box sx={{ position: 'absolute' }}>
                <ReactPlayer
                    playing={true}
                    playsinline={true}
                    muted={true}
                    loop={true}
                    url={video.url}
                    width={width}
                    height={height}
                    config={{
                        youtube: {
                            playerVars: {
                                start: video.start_time,
                                end: video.end_time
                            }
                        }
                    }}
                />
            </Box>

            <Box sx={{ position: 'relative', padding: "20px" }}>
                <Typography color="pink" variant="h4">SQUAT</Typography>
                <Typography color="pink" variant="h5">10 sets x 3 reps | open time</Typography>
                {/* <Typography variant="body1">click next when you are done!</Typography> */}
            </Box>

            <Box sx={{ position: 'relative', paddingLeft: "20px" }}>
                <Typography mt={2} color="lime" variant="h1">{timer}</Typography>
            </Box>

            {/* <Box sx={{ position: 'fixed', bottom: 50, left: 20}}>
                <Webcam
                    audio={true}
                    // ref={webcamRef}
                    muted={true}
                    // videoConstraints={videoConstraints}
                    height={height*0.2}
                />
            </Box> */}

            <Box sx={{ position: 'fixed', top: 20, right: 20 }}>
                <MenuIcon fontSize="large" />;
            </Box>

            <Box sx={{ position: 'fixed', bottom: 10, left: 10 }}>
                <img src={logo} alt="Logo" height={0.1 * height} />;
            </Box>

            <Box sx={{ position: 'fixed', bottom: 20, right: 20 }}>
                <Typography color="pink" variant="h4">Reuben Loveszy</Typography>
            </Box>





        </>
    );
}

export default Player;