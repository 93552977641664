import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";
import { useRouteMatch, Link, useLocation } from "react-router-dom";
import { Button, Table } from "semantic-ui-react";

import axios from "axios";
import ReactPlayer from 'react-player'

import { HttpRequest } from "@aws-sdk/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
import { Hash } from "@aws-sdk/hash-node";
import { formatUrl } from "@aws-sdk/util-format-url";

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const userFeaturesByUserVideoIdSuffix = process.env.REACT_APP_EXPRESS_SERVER_USER_FEATURES_USER_VIDEO_ID_SUFFIX;
const userFeaturesURL = serverURL + userFeaturesByUserVideoIdSuffix;

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;

var credentials = {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region
}

function UserVisualization() {
    const [userFeature, setUserFeature] = useState(null);
    const { params: { userVideoId } } = useRouteMatch();
    const [url, setURL] = useState("");
    const [userSession, setUserSession] = useState(null);
    const [movement, setMovement] = useState({ "movementName": "" });
    const [targetGoals, setTargetGoals] = useState({ "setsGoals": { "setsNumber": null } });
    const [sessionState, setSessionState] = useState({ "setNumber": null });
    const [datetime, setDateTime] = useState("");
    const [duration, setDuration] = useState("");
    var setsNumber = 1;

    useEffect(() => {
        if (userVideoId) {
            const fetchData = async () => {
                return await axios.get(`${userFeaturesURL}/${userVideoId}`);
            };
            fetchData()
                .then(({ data: { userFeature } }) => {
                    setUserFeature(userFeature);
                    setUserSession(userFeature.user_session);
                    setMovement(userFeature.user_session.movement);
                    setSessionState(userFeature.user_session.session_state);
                    setTargetGoals(userFeature.user_session.target_goals);
                    var date = new Date(userFeature.user_session.timestamp_start);

                    if (typeof userFeature.user_session.target_goals.setsGoals != "undefined") {
                        setsNumber = userFeature.user_session.target_goals.setsGoals.setsNumber;
                    }
                    setDateTime(date.toLocaleTimeString([], { weekday: "short", year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }));
                    setDuration(Math.round((userFeature.user_session.timestamp_end - userFeature.user_session.timestamp_start) / 1000));
                    return userFeature
                })
                .then(async (retrievedUserFeature) => {
                    let video = retrievedUserFeature.user_visualization_video;
                    let bucket = video.bucket;
                    let key = video.key;
                    let region = "us-west-2";
                    // https://aws.amazon.com/blogs/developer/generate-presigned-url-modular-aws-sdk-javascript/
                    const s3ObjectUrl = parseUrl(`https://${bucket}.s3.${region}.amazonaws.com/${key}`);
                    const presigner = new S3RequestPresigner({
                        credentials,
                        region,
                        sha256: Hash.bind(null, "sha256"), // In Node.js
                        //sha256: Sha256 // In browsers
                    });
                    // Create a GET request from S3 url.
                    const assignedURL = await presigner.presign(new HttpRequest(s3ObjectUrl));
                    // console.log("PRESIGNED URL: ", formatUrl(assignedURL));
                    setURL(formatUrl(assignedURL));
                    // console.log({ retrievedUserFeature })
                })
        }
    }, [userVideoId]);

    return (
        <section>
            <header>
                <article className="container">
                    <Table
                        color="black"
                        key="black"
                        inverted
                        size="small"
                        className="movement-table"
                    >
                        <Table.Body>
                            <Table.Row key={userVideoId}>
                                <Table.Cell>{movement.movementName}</Table.Cell>
                                <Table.Cell>{"Set: " + sessionState.setNumber + " / " + setsNumber}</Table.Cell>
                                <Table.Cell>{datetime}</Table.Cell>
                                <Table.Cell>{duration + " secs"}</Table.Cell>
                                <Table.Cell>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </article>
                <container>
                    <Fragment>
                        <Button
                            color="blue"
                            inverted
                            className="btn__submit--movement"
                            as={Link}
                            to={{ pathname: "/dashboard" }}
                        >
                            Go to Dashboard
                        </Button>

                        <Button
                            color="green"
                            inverted
                            className="btn__submit--movement"
                            as={Link}
                            to={{ pathname: "/user_sessions" }}
                        >
                            My workout videos
                        </Button>

                        <Button
                            color="pink"
                            inverted
                            className="btn__submit--movement"
                            as={Link}
                            to={{ pathname: "/sessions˝" }}
                        >
                            Try another workout
                        </Button>
                    </Fragment>
                </container>
                <div className="player-wrapper container" >
                    <ReactPlayer
                        playing={true}
                        playsinline={true}
                        muted={true}
                        loop={true}
                        url={url}
                    />
                </div>
            </header>


        </section>
    )
}

export default UserVisualization;
