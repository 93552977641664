export const equipmentOptions = [
  { key: "barbell", text: "Barbell", value: "barbell" },
  { key: "plates", text: "Plates", value: "plates" },
  { key: "dumbbell-set", text: "Dumbbells", value: "dumbbell" },
  { key: "kettlebell-set", text: "Kettlebells", value: "kettlebell-set" },
  { key: "pullup-bar", text: "Pull UP Bar", value: "pullup-bar" },
  { key: "rowing-machine", text: "Rowing Machine", value: "rowing-machine" },
  { key: "stationary-bike", text: "Stationary Bike", value: "stationary-bike" },
  { key: "resistance-band", text: "Resistance Band", value: "resistance-band" }, 
  { key: "knee_ring_bands", text: "knee-bands", value: "knee_ring_bands" }, 
  { key: "gymnastic_rings", text: "Gymnastic Rings", value: "gymnastic_rings" }, 
  { key: "yoga_mat", text: "Yoga Mat", value: "yoga_mat" },
  { key: "grip_strength_trainer", text: "Grip Strength Trainer", value: "grip_strength_trainer" },
  { key: "medicine_ball", text: "Medicine Ball", value: "medicine_ball" },
];

export const workoutCategoryOptions = [
  { key: "strength", text: "Strength", value: "strength" },
  { key: "weightlifting", text: "Weightlifting", value: "weightlifting" },
  { key: "olympic-weightlifting", text: "Olympic Weightlifting", value: "olympic-weightlifting" },
  { key: "endurance", text: "Endurance", value: "endurance" },
  { key: "functional-training", text: "Functional Training", value: "functional-training" },
  { key: "flexibility", text: "Flexibility", value: "flexibility" },
  { key: "mobility", text: "Mobility", value: "mobility" },
  { key: "mobility-test", text: "Mobility Test", value: "mobility-test" },
  { key: "core", text: "core", value: "core" },
  { key: "weight-losing", text: "Weight Losing", value: "weight-losing" },
  { key: "sculpting", text: "Sculpting", value: "Sculpting" },
  { key: "cardio", text: "Cardio", value: "cardio" },
  { key: "kickboxing", text: "Kickboxing", value: "kickboxing" },
  { key: "balance", text: "Balance", value: "balance" },
  { key: "yoga", text: "Yoga", value: "yoga" },
  { key: "gymnastics", text: "Gymnastics", value: "gymnastics" },
  { key: "warmpup", text: "Warmup", value: "warmpup" },
  { key: "cool-down", text: "Cool Down", value: "cool-down" },
  { key: "calisthenics", text: "Calisthenics", value: "calesthenics" },
  { key: "stretch", text: "Stretch", value: "stretch" },
  { key: "pose-correction", text: "Pose Correction", value: "pose-correction" },
  { key: "body-weight", text: "Body Weight", value: "body-weight" },
  { key: "resistence-training", text: "Resistance Training", value: "resistence-training" },
];

export const videoSourceOptions = [
  { key: "youtube", text: "Youtube", value: "youtube" },
  { key: "vimeo", text: "Vimeo", value: "vimeo" },
  { key: "s3", text: "S3", value: "s3" },
];

export const roundGoalType = [
  {
    key: "AMRAP - Max Reps",
    text: "AMRAP - Max Reps",
    value: "AMRAP - Max Reps",
  },
  {
    key: "AMRAP - Max Rounds and Reps",
    text: "AMRAP - Max Rounds and Reps",
    value: "AMRAP - Max Rounds and Reps",
  },
  {
    key: "FIXED Rounds/Reps - MIN Time",
    text: "FIXED Rounds/Reps - MIN Time",
    value: "FIXED Rounds/Reps - MIN Time",
  },
];
export const setsGoalType = [
  {
    key: "FIXED SETS - FIXED TIME - FIXED REPS",
    text: "FIXED SETS - FIXED TIME - FIXED REPS",
    value: "FIXED SETS - FIXED TIME - FIXED REPS",
  },
  {
    key: "FIXED SETS - FIXED TIME - MAX Reps",
    text: "FIXED SETS - FIXED TIME - MAX Reps",
    value: "FIXED SETS - FIXED TIME - MAX Reps",
  },
  {
    key: "FIXED SETS - FIXED REPS - MIN TIME",
    text: "FIXED SETS - FIXED REPS - MIN TIME",
    value: "FIXED SETS - FIXED REPS - MIN TIME",
  },
  {
    key: "MAX SETS - FIXED TIME - MAX REPS",
    text: "MAX SETS - FIXED TIME - MAX REPS",
    value: "MAX SETS - FIXED TIME - MAX REPS",
  },
  {
    key: "CLEAR THE GOAL",
    text: "CLEAR THE GOAL -- NONE",
    value: null,
  },
];

export const setsTimeGoalType = [
  {
    key: "None",
    text: "None",
    value: null,
  },
  {
    key: "SETS TOTAL TIME",
    text: "SETS TOTAL TIME",
    value: "SETS TOTAL TIME",
  },
  {
    key: "TIME PER SET",
    text: "TIME PER SET",
    value: "TIME PER SET",
  },
  {
    key: "EMOM",
    text: "EMOM",
    value: "EMOM",
  },
  {
    key: "E90SEC",
    text: "E90SEC",
    value: "E90SEC",
  },
  {
    key: "E2MIN",
    text: "E2MIN",
    value: "E2MIN",
  },
];

export const timeUnit = [
  {
    key: "minutes",
    text: "Minutes",
    value: "minutes",
  },
  {
    key: "seconds",
    text: "Seconds",
    value: "seconds",
  },
];
