
export const initialSession = {
    sessionMetadata: null,
    sessionEntryList: []
}

export const movementDefault = {
    movementName: null,
    videoUrl: null,
    videoStartTime: null,
    videoEndTime: null,
    equipmentList: [],
    videoSource: null,
    workoutCategoryList: [],
}

export const sessionEntryDefault = {
    sessionEntryType: null,  // pod, round, movement, video
    pod: null,
    podMetadata: null,
    round: null,
    movement: null,
    targetGoals: null,
    video: null,
    action: null,
    comment: null,
};

export const setsGoalsDefault = {
    setsNumber: null,
    setsGoalType: null,
    timeGoalType: null,
    timePerSet: null,
    setsTotalTime: null,
    timeUnit: null,
    useDifferentRepsPerSet: false,
    restTimeBetweenSets: null,
};

export const repsGoalsDefault = {
    repsNumber: null,
    repsPerSetArray: null,
    repsPerRoundArray: null,
};
