import React, { useState, useEffect } from "react";
import { userFitnessStateGoalInitial } from "./constants"
import { useRouteMatch } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import WeightInput from './WeightInput';
import WeightGoals from "./WeightGoals";
import FitnessLevel from "./FitnessLevel";

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { Dimmer, Header, Icon } from "semantic-ui-react";
import axios from "axios";
import { TurnSharpLeftRounded } from "@mui/icons-material";

import { useAuth0 } from "@auth0/auth0-react";

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const userFitnessStateGoalSuffix = process.env.REACT_APP_EXPRESS_SERVER_USER_FITNESS_STATE_GOAL_SUFFIX;
const userFitnessStateGoalURL = serverURL + userFitnessStateGoalSuffix;



const formsArray = [
    "weight_input",
    "weight_goals",
    "current_fitness_level",
    "target_fitness_level",
    // "current_strength_level",
    // "target_strength_level",
    // "current_mobility_level",
    // "target_mobility_level",
    // "current_pain_level",
    // "target_rehabilitation_level",
]
const units = {
    weight_unit: {
        us_units: "lb",
        metric_units: "kg"
    },
    height_unit: {
        us_units: "inch",
        metric_units: "cm"
    }

}

function UserFitnessStateGoal() {
    const [unit, setUnit] = useState("us_units");
    const [formIndex, setFormIndex] = useState(0);
    const [userFitnessStateGoal, setUserFitnessStateGoal] = useState(userFitnessStateGoalInitial)
    const numForms = formsArray.length;
    // const [open, setOpen] = useState(null);
    const [isSuccess, setIsSuccess] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    console.log(userFitnessStateGoal);

    const { user } = useAuth0();

    useEffect(() => {
        if (user != undefined) {
            setUserFitnessStateGoal((prevState) => {
                return {
                    ...prevState,
                    user: user,
                };
            }
            )
        }
    }, [user]);

    const { params: { userFitnessStateGoalId } } = useRouteMatch();
    useEffect(() => {
        if (userFitnessStateGoalId) {
            const fetchData = async () => {
                console.log(`${userFitnessStateGoalURL}/${userFitnessStateGoalId}`);
                return await axios.get(`${userFitnessStateGoalURL}/${userFitnessStateGoalId}`);
            };
            fetchData()
                .then(({ data: { userFitnessStateGoal } }) => {
                    setUserFitnessStateGoal(userFitnessStateGoal);
                    return userFitnessStateGoal;
                })
                .then((userFitnessStateGoal) => {
                    console.log(userFitnessStateGoal);
                })
        }
    }, [userFitnessStateGoalId]);

    const onNextButtonClickHandler = () => {
        setFormIndex((prevState) => { return prevState + 1 })
    }

    const onBackButtonClickHandler = () => {
        setFormIndex((prevState) => { return prevState - 1 })
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        console.log(userFitnessStateGoal);
        try {
            axios({
                url: userFitnessStateGoalURL,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: userFitnessStateGoal,
            }).then((res) => {
                console.log(res);
                setIsSuccess(true);
            });

        } catch ({
            response: {
                data: { error },
            },
        }) {
            setErrorMsg(error.message);
            setIsSuccess(false);

        }
    }

    return (
        <Container
            sx={{
                width: '100%', '& > :not(style)':
                    { m: 2, width: '35ch' },
            }}>

            {formsArray[formIndex] === "weight_input" &&
                <WeightInput
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                    units={units}
                    unit={unit}
                    setUnit={setUnit}
                />
            }

            {formsArray[formIndex] === "weight_goals" &&
                <WeightGoals
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                    units={units}
                    unit={unit}
                    setUnit={setUnit}
                />
            }

            {formsArray[formIndex] === "current_fitness_level" &&
                <FitnessLevel
                    fitness_key="current_fitness_level"
                    title="Current Fitness Level"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "target_fitness_level" &&
                <FitnessLevel
                    fitness_key="target_fitness_level"
                    title="What are your priorities?"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "current_pain_level" &&
                <FitnessLevel
                    fitness_key="current_pain_level"
                    title="Current Pain Level"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "target_rehabilitation_level" &&
                <FitnessLevel
                    fitness_key="target_rehabilitation_level"
                    title="Pain Relief and Rehab Priorities"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "current_strength_level" &&
                <FitnessLevel
                    fitness_key="current_strength_level"
                    title="Current Strength Level"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "target_strength_level" &&
                <FitnessLevel
                    fitness_key="target_strength_level"
                    title="Strength Training Priorities"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "current_mobility_level" &&
                <FitnessLevel
                    fitness_key="current_mobility_level"
                    title="Current Mobility Level"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            {formsArray[formIndex] === "target_mobility_level" &&
                <FitnessLevel
                    fitness_key="target_mobility_level"
                    title="Mobility Training Priorities"
                    userFitnessStateGoal={userFitnessStateGoal}
                    setUserFitnessStateGoal={setUserFitnessStateGoal}
                />
            }

            <Stack spacing={1} direction="row" justifyContent={"center"}>
                {formIndex != 0 &&
                    <Button variant="outlined" onClick={onBackButtonClickHandler}>Back</Button>
                }
                <Button variant="outlined" onClick={onSubmitHandler}>Save & Exit</Button>
                {formIndex < numForms - 1 &&
                    <Button variant="outlined" onClick={onNextButtonClickHandler}>Next</Button>
                }
            </Stack>

            {isSuccess !== null && isSuccess === false && (
                <Alert onClose={() => { setIsSuccess(null) }} severity="error">{errorMsg}</Alert>
            )}

            {isSuccess && (
                <>
                    <Alert onClose={() => { setIsSuccess(null) }} severity="success">"Data entry was successful."</Alert>
                    <Redirect to="/dashboard" />
                </>

            )}

        </Container>
    );
}

export default UserFitnessStateGoal;