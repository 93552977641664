import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Button, Icon, Header, Dimmer } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import Movement from "./Movement"
import SessionHeader from "./Header/SessionHeader";
import Buttons from "./Buttons";
import { isMobile } from 'react-device-detect';
import SessionTable from "./SessionTable";
import SessionMetaData from "./SessionMetaData";
import {
    movementFormValidation,
} from "./utils/validations";

import {
    movementDefault,
    sessionEntryDefault,
    setsGoalsDefault,
    repsGoalsDefault
} from "./Defaults"

require("dotenv").config();

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const movementsSuffix = process.env.REACT_APP_EXPRESS_SERVER_MOVEMENTS_SUFFIX;
const movementsURL = serverURL + movementsSuffix;
const sessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_SESSIONS_SUFFIX;
const sessionsURL = serverURL + sessionsSuffix;

function Session() {

    const {
        params: { sessionId },
    } = useRouteMatch();
    console.log(sessionId);

    const [allMovements, setAllMovements] = useState([]);

    const fetchMovements = async () => {
        const {
            data: { allMovements },
        } = await axios.request({
            url: movementsURL,
            method: "GET",
        });
        setAllMovements(allMovements);
        console.log(allMovements)
    };

    useEffect(() => {
        fetchMovements();
    }, []);

    const [sessionState, setSessionState] = useState({
        sessionMetadata: null,
        sessionEntryList: []
    });

    var [sessionMetadata, setsessionMetadata] = useState({
        sessionName: null,
        workoutCategoryList: [],
        contentOwner: null,
    });

    var [session, setSession] = useState({
        sessionMetadata: null,
        sessionEntryList: []
    });

    const [movement, setMovement] = useState(movementDefault);
    var [setsGoals, setSetsGoals] = useState(setsGoalsDefault);
    var [repsGoals, setRepsGoals] = useState(repsGoalsDefault);
    var [sessionEntry, setSessionEntry] = useState(sessionEntryDefault);
    var [sessionEntryList, setSessionEntryList] = useState([]);
    var [sessionMetadata, setSessionMetadata] = useState({
        sessionName: null,
        workoutCategoryList: [],
        contentOwner: null,
    });

    const buttonStateInitial = {
        pod: false,
        round: false,
        movement: false,
        video: false
    }
    const [buttonState, setButtonState] = useState(buttonStateInitial);

    useEffect(() => {
        if (sessionId) {
            const fetchData = async () => {
                console.log(`${sessionsURL}/${sessionId}`);
                return await axios.get(`${sessionsURL}/${sessionId}`);
            };
            fetchData()
                .then(({ data: { session } }) => {
                    setSessionState(session);
                    setSession(session);
                    return session;
                })
                .then((retrievedSession) => {
                    setSessionMetadata(retrievedSession.sessionMetadata);
                    setSessionEntryList(retrievedSession.sessionEntryList);
                    session = retrievedSession;
                    console.log(retrievedSession.sessionEntryList);
                })
        }
    }, [sessionId]);


    const onSaveMovementsHandler = () => {
        console.log(session);
        try {
            // await movementFormValidation.validate(movement);
            sessionEntry =
            {
                sessionEntryType: "movement",
                pod: null,
                podMetadata: null,
                round: null,
                movement: movement,
                targetGoals: {
                    roundGoals: null,
                    setsGoals: setsGoals,
                    repsGoals: repsGoals,
                    cardioGoals: null,
                    intensityGoals: null,
                    weightGoals: null,
                },
                video: null,
                action: null,
                comment: null,
            };
            sessionEntryList.push(sessionEntry);
            session = {
                sessionEntryList: sessionEntryList,
                sessionMetadata: sessionMetadata
            }
            setSessionState(session);
            console.log(session);

            // reset some of the state variables
            setButtonState(buttonStateInitial)
            setSessionEntry(sessionEntryDefault)
            setMovement(movementDefault)
            setSetsGoals(setsGoalsDefault)
            setRepsGoals(repsGoalsDefault)
        } catch ({ message }) {
            toast.error(message, { position: toast.POSITION.TOP_LEFT });
        }
    };

    const [open, setOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            if (buttonState.movement) {
                onSaveMovementsHandler();
            }
            await axios({
                url: sessionsURL,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                data: sessionState,
            });
            setIsSuccess(true);
        } catch ({
            response: {
                data: { error },
            },
        }) {
            setOpen(true);
            setErrorMsg(error.message);
        }
    };

    const history = useHistory();
    const handleLooperLauncher = () => {
        history.push(
            "/looper",
            { inputSession: sessionState }
        );
    };


    return (
        <section>
            {!isMobile && <SessionHeader />}
            <SessionMetaData
                sessionMetadata={sessionMetadata}
                setSessionMetadata={setSessionMetadata}
            />
            <SessionTable inputSession={sessionState} />

            {buttonState.movement && (
                        <Movement
                            movement={movement}
                            setMovement={setMovement}
                            insideRound={false}
                            allMovements={allMovements}
                            setsGoals={setsGoals}
                            setSetsGoals={setSetsGoals}
                            repsGoals={repsGoals}
                            setRepsGoals={setRepsGoals}
                        />
            )}

            <container className="btn">
                <Buttons
                    buttonState={buttonState}
                    setButtonState={setButtonState}
                />
            </container>

            <container className="container">
                <Fragment>
                    <Button
                        as="button"
                        color="yellow"
                        className="btn__submit--movement"
                        inverted
                        type="submit"
                        onClick={onSaveMovementsHandler}
                    >
                        <Button.Content visible>SAVE</Button.Content>
                    </Button>

                    <Button
                        as="button"
                        color="green"
                        className="btn__submit--movement"
                        inverted
                        type="submit"
                        onClick={onSubmitHandler}
                    >
                        <Button.Content visible>SUBMIT</Button.Content>
                    </Button>

                    <Button
                        as="button"
                        color="orange"
                        className="btn__submit--movement"
                        inverted
                        type="submit"
                        onClick={handleLooperLauncher}
                    >
                        <Button.Content visible>LAUNCH</Button.Content>
                    </Button>
                </Fragment>
            </container>


            {open && (
                <Dimmer active={open} onClickOutside={() => setOpen(false)} page>
                    <Header as="h1" icon inverted>
                        <Icon name="eject" />
                        Error found in your fields
                        <Header.Subheader>
                            <h2>{errorMsg}</h2>
                        </Header.Subheader>
                    </Header>
                </Dimmer>
            )}

            {isSuccess && (
                <Dimmer
                    active={isSuccess}
                    onClickOutside={() => setIsSuccess(false)}
                    page
                >
                    <Header as="h1" icon inverted>
                        <Icon name="diamond" />
                        Done
                        <Header.Subheader>Data has been sent successfully</Header.Subheader>
                    </Header>
                </Dimmer>
            )}

        </section>
    );

}

export default Session;