import React, { useState } from "react";

// import { Table, Input } from "semantic-ui-react"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

const marks = [
    {
        value: 1,
        label: 'low',
    },
    // {
    //     value: 3,
    //     label: 'low',
    // },
    {
        value: 5,
        label: 'moderate',
    },
    // {
    //     value: 7,
    //     label: 'high',
    // },
    {
        value: 9,
        label: 'high',
    }
];

const overall_marks = [
    {
        value: 1,
        label: 'beginner',
    },
    // {
    //     value: 3,
    //     label: 'novice',
    // },
    {
        value: 5,
        label: 'intermediate',
    },
    // {
    //     value: 7,
    //     label: 'pro',
    // },
    {
        value: 9,
        label: 'advanced',
    }
];


function FitnessLevel({ fitness_key, title, userFitnessStateGoal, setUserFitnessStateGoal }) {
    console.log(fitness_key)
    const onChangeHandler = ({ target: { name, value } }) => {
        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                [fitness_key]: { ...prevState[fitness_key], [name]: value },
            };
        });
    };
    return (
        <Box
            sx={{
                width: '100%', '& > :not(style)':
                    { mb: 0, width: '35ch', border: 0 },
            }}>
            <Typography variant="h5" component="div" gutterBottom>
                {title}
            </Typography>
            <Table>
                {(
                    typeof userFitnessStateGoal !== undefined ||
                    typeof userFitnessStateGoal[fitness_key] !== undefined ||
                    typeof fitness_key !== undefined
                ) &&
                    Object.keys(userFitnessStateGoal[fitness_key]).map((currentfitness_key, index) => {
                        return (
                            <TableRow
                                key={index}
                                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ width: 30 }}>
                                    {currentfitness_key}
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ width: 180 }}>
                                        <Slider
                                            track="inverted"
                                            aria-label="Custom marks"
                                            defaultValue={5}
                                            step={4}
                                            valueLabelDisplay="off"
                                            marks={currentfitness_key==="overall"? overall_marks: marks}
                                            min={1}
                                            max={9}
                                            value={userFitnessStateGoal[fitness_key][currentfitness_key] || 5}
                                            name={currentfitness_key}
                                            onChange={onChangeHandler}
                                        />
                                    </Box>
                                </TableCell>

                            </TableRow>
                        );
                    }
                    )
                }
            </Table>

        </Box>

    );
}

export default FitnessLevel;