import { Button, Table } from "semantic-ui-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

// FILES:   _______  CSS Files _______
// import "semantic-ui-css/semantic.min.css";

// import "./allMovements.css";
import { Link } from "react-router-dom";

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const movementsSuffix = process.env.REACT_APP_EXPRESS_SERVER_MOVEMENTS_SUFFIX;
const movementsURL = serverURL + movementsSuffix;

function AllMovements() {
  const queryClient = useQueryClient();
  const { isLoading, isError, data } = useQuery(
    ["allMovementsQuery"],
    async () => {
      return await (
        await axios.get(movementsURL)
      ).data;
    }
  );

  const { mutate, isSuccess } = useMutation(async (movementId) => {
    await axios.delete(`${movementsURL}/${movementId}`);
  });

  const deleteHandler = (movementId) => {
    mutate(movementId);
  };

  if (isSuccess) {
    queryClient.invalidateQueries("allMovementsQuery");
  }
  if (isLoading) {
    return <h1>Loading . . . </h1>;
  }
  if (isError) {
    return <h1>Error . . . </h1>;
  }

  return (
    <section>
      <header>
        <h1>Movement and Demo Video Library</h1>
        <Button
          color="blue"
          inverted
          className="btn--add--data"
          as={Link}
          to={{ pathname: "/movement" }}
        >
          Add a movement and demo video data
        </Button>
      </header>
      <article className="container">
        <Table
          color="black"
          key="black"
          inverted
          size="small"
          className="movement-table"
        >
          <Table.Body>
            {data &&
              data.allMovements.map(({ movementName, _id: movementId }) => {
                return (
                  <Table.Row key={movementId}>
                    <Table.Cell>{movementName}</Table.Cell>
                    <Table.Cell>
                      <Button
                        inverted
                        color="orange"
                        className="btn--modify"
                        as={Link}
                        to={{ pathname: `/movement/${movementId}` }}
                      >
                        VIEW
                      </Button>
                      {/* <Button
                        inverted
                        color="orange"
                        className="btn--delete"
                        onClick={() => deleteHandler(movementId)}
                      >
                        Delete
                      </Button> */}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </article>
    </section>
  );
}

export default AllMovements;
