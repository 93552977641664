import { Fragment } from "react";

function Instruction() {
  return (
    <Fragment>
      <p style={{ padding: "40px" }}>
        Follow the instruction to create a new workout session from scratch. A
        session is a data structure that stores all the features needed to
        create a workout session. <br /> <br /> A workout session is a list of movement
        blocks. A movement block can be a single movement, a list of movements,
        a round of movements, or a pod of movements. A session can consist of
        any combination of the movement blocks. <br /> <br /> A workout session
        can be organized as multiple pods. Each workout pod is a list of
        movements or round of movements that are ordered and grouped together
        based on their target fitness goals. For example, a common Functional
        Training or a CrossFit workout session (45 to 60 mins) is usually
        consisted of 3, 4 pods such as warmup, strength building (e.g.
        weightlifting or olympic), endurance training and gymnastics. A Yoga
        session can be consisted of pods that focus on flexibility, mobility,
        release, breathing, balance. A calisthenic sessions can be consisted of
        pods to target core strength training, gymnastics and body sculpting.{" "}
        <br /> <br /> A session stores enough information to automate the demo,
        timing and recording of athletes videos. These include movements
        features, each movement’s related target performance goals (such as
        workout and rest time and target quantity, energy, weights capacity),
        each movement’s demo video and player control parameters, the
        looper/timer properties and athletes’ video recording permissions during
        the actual workout time
      </p>
    </Fragment>
  );
}

export default Instruction;
