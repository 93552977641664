export const getWidth = () => { return window.screen.width }

export const getHeight = () => { return window.screen.height }

export const getOrientation = () => { 
      let orientation = window.screen.orientation.type;
      if (orientation.includes("landscape")) {
        return "landscape";
      }
      
      if (orientation.includes("portrait")) {
        return "portrait";
      }
      
      if (orientation === undefined) {
        alert("orientation not supported in current browser");
        return -1;
      }
}


