import React from "react";
import {
    Form,
    Input,
    Popup,
} from "semantic-ui-react";


function RepsGoals({ repsGoals, setRepsGoals, insideRound }) {

    const onChangeRepsHandler = ({ target: { name, value } }) => {
        if (name === "repsPerSetArray") {
            const arrayOfValue = value.split(" ").join("").split(",").map(x => Number(x));

            setRepsGoals((prevState) => {
                return {
                    ...prevState,
                    repsPerSetArray: arrayOfValue,
                };
            });
        }
        else if (name === "repsPerRoundArray") {
            const arrayOfValue = value.split(" ").join("").split(",").map(x => Number(x));

            setRepsGoals((prevState) => {
                return {
                    ...prevState,
                    repsPerSetArray: arrayOfValue,
                };
            });
        } else {
            setRepsGoals((prevState) => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        }
    };

    return (
        <section className="form-control-session">
            <header className="movement">Reps Goals</header>
            {/* <Form> */}
            {/* <Form.Field> */}
            <form autoComplete="on" className="form--movement--control">
                <Popup
                    trigger={
                        <Input
                            placeholder="Reps Number"
                            className="equipment-select"
                            type="text"
                            name="repsNumber"
                            onChange={onChangeRepsHandler}
                            value={repsGoals.repsNumber || ""}
                        />
                    }
                    content="Target Reps"
                />
                {/* </Form.Field> */}
                {/* {!insideRound &&
                
                    <Popup
                        trigger={
                            <Input
                                placeholder={"Array of Reps per Set"}
                                className="equipment-select"
                                type="text"
                                name="repsPerSetArray"
                                onChange={onChangeRepsHandler}
                                value={repsGoals.repsPerSetArray || ""}
                            />
                        }
                        content="Target Reps Array"
                    />
                } */}
                {insideRound &&
                    // <Form.Field>
                    <Popup
                        trigger={
                            <Input
                                placeholder="Array of Reps per Round"
                                className="equipment-select"
                                type="text"
                                name="repsPerRoundArray"
                                onChange={onChangeRepsHandler}
                                value={repsGoals.repsPerRoundArray || ""}
                            />
                        }
                        content="Target Reps Array"
                    />
                    // </Form.Field>
                }
                {/* </Form> */}

            </form>
        </section >

    )
}

export default RepsGoals;