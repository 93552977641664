import React, { Fragment, useState, useEffect } from 'react'

import Container from '@mui/material/Container';
import { Button } from '@mui/material';

import { isMobile } from 'react-device-detect';
import { useAuth0 } from "@auth0/auth0-react";
import { styled } from '@mui/material/styles';


function Dashboard() {
    const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();
    const [userSessionURL, setUserSessionURL] = useState("user_sessions/user_id");
    const [userId, setUserId] = useState("");

    useEffect(() => {
        if (user != undefined) {
            setUserId(user.sub);
            setUserSessionURL("user_sessions/user_id/" + user.sub);
        }
    }, [user]);


    return (
        // button colors: https://htmlcolorcodes.com/color-names/
        <Container sx={{
            width: '100%', '& > :not(style)':
                { m: 2, width: '35ch' },
        }}>
            <br></br>
            <Button variant="outlined" href="/sessions" style={{ color: "Magenta" }}>
                set your fitness goals
                <br></br>share your current state
            </Button>
            <br></br>
            <Button variant="outlined" href="https://calendly.com/yrafati/30min" style={{ color: "DodgerBlue" }}>
                private personal training
                <br></br>free | for first-timers
            </Button>
            <br></br>
            <Button variant="outlined" href="https://calendly.com/yrafati/30min" style={{ color: "Orange" }}>
                private personal training
                <br></br>$30 | 30 mins
            </Button>
            <br></br>
            <Button variant="outlined" href="/sessions" style={{ color: "DeepPink" }}>
                OPTIM AI Gym
                <br></br>FREE | Online | Powered by AI
                <br></br>
                <br></br>AI workout recommenations
                <br></br>AI movement analysis
            </Button>
            <br></br>
            <Button variant="outlined" href="https://calendly.com/yrafati/30min" style={{ color: "Lime" }}>
                OPTIM Pro Membership
                <br></br>$60/mo | Experts got your back
                <br></br>
                <br></br>Weekly fitness planning
                <br></br>Exclusive content
            </Button>
            <br></br>


            <Button variant="outlined" size="large" href="mailto:jacobrafati@workout.vision" style={{ color: "LightCoral" }}>
                send your feedback
            </Button>

            <br></br>

            {isAuthenticated && (
                <Button variant="outlined" size="large" style={{ color: "Orange" }} onClick={() => logout()}>
                    logout
                </Button>)
            }

        </Container>);
}

export default Dashboard;
