import { Fragment, useState } from "react";
import Instruction from "./Instruction";
import { Button, Container } from "semantic-ui-react";

export default function SessionHeader() {
  const [instructionState, setInstructionState] = useState(false);

  return (
    <Fragment>
      <Container className="container">
        <header>
          <h1>Create a Workout Session</h1>
        </header>
        {/* <Button
          content="Instruction"
          className="instruction"
          basic
          color="orange"
          size="big"
          onClick={setInstructionState.bind(this, !instructionState)}
        />
        {instructionState && (
            <Instruction />
        )} */}
      </Container>
    </Fragment>
  );
}
