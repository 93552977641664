import React, { useState } from 'react'
import { Button, Checkbox, Container, Form, Input } from 'semantic-ui-react'

const WorkoutRecommendations = () => {
  const [workoutRecommendationsMacros, setWorkoutRecommendationsMacros] = useState(
    {
      "workout_intensity": 5,
      "workout_time": 30,
      "fitness_dimensions_distribtions": {
        "strength": 5,
        "mobility": 5,
        "endurance": 5,
        "agility": 5,
        "rehabilitation": 5
      },
      "body_parts_distribtions": {
        "legs": 5,
        "arms": 5,
        "shoulders": 5,
        "abs": 5,
        "chest": 5,
        "back": 5
      }
    }
  )
  console.log(workoutRecommendationsMacros)
  const onFitnessMacrosChangeHandler = ({ target: { name, value } }) => {
    setWorkoutRecommendationsMacros((prevState) => {
      return {
        ...prevState,
        "fitness_dimensions_distribtions": { ...prevState.fitness_dimensions_distribtions, [name]: Number(value) },
      };
    });
  };
  const onBodyPartsMacrosChangeHandler = ({ target: { name, value } }) => {
    setWorkoutRecommendationsMacros((prevState) => {
      return {
        ...prevState,
        "body_parts_distribtions": { ...prevState.body_parts_distribtions, [name]: Number(value) },
      };
    });
  };
  const onChangeHandler = ({ target: { name, value } }) => {
    setWorkoutRecommendationsMacros((prevState) => {
      return {
        ...prevState,
        [name]: Number(value),
      };
    });
  };
  return (
    <Container>

      <Form inverted>
        {/* <h3>Press green button to get balanced macro recommendations or enter your macros manually.
          Then, press yellow button to get movements and target goals recommendations based on your macros. </h3> */}
          <h3>Enter your workout macros, then press the button to get movements and target goals recommendations.</h3>

        {/* <Form.Field>
          <Button inverted color="green" type='submit'>workout macros recommendations</Button>
        </Form.Field> */}
        {/* <Form.Field>
          <Button inverted color="yellow" type='submit'>movements + target goals recommendations</Button>
        </Form.Field> */}
        <h3>Workout Macros</h3>
        <Form.Field inline>
          <label>Workout Time</label>
          <Input
            placeholder='in minutes'
            name={"workout_time"}
            value={workoutRecommendationsMacros.workout_time}
            onChange={onChangeHandler} />
        </Form.Field>
        <Form.Field inline>
          <label>Workout Intensity</label>
          <Input
            type="range"
            name={"workout_intensity"}
            min={0}
            max={10}
            value={workoutRecommendationsMacros.workout_intensity}
            onChange={onChangeHandler}
          />{workoutRecommendationsMacros.workout_intensity}
        </Form.Field>
        <h3>Fitness Dimensions Distribution</h3>
        <Form.Group>
          {true && Object.keys(workoutRecommendationsMacros.fitness_dimensions_distribtions).map((current_key, index) => {
            return (
              <Form.Field inline>
                <label>{current_key}</label>
                <Input
                  type="range"
                  name={current_key}
                  min={0}
                  max={10}
                  value={workoutRecommendationsMacros.fitness_dimensions_distribtions[current_key]}
                  onChange={onFitnessMacrosChangeHandler}
                />{workoutRecommendationsMacros.fitness_dimensions_distribtions[current_key]}
              </Form.Field>
            )
          }
          )
          }
        </Form.Group>

        <h3>Body Parts Distribution</h3>
        <Form.Group>
          {true && Object.keys(workoutRecommendationsMacros.body_parts_distribtions).map((current_key, index) => {
            return (
              <Form.Field inline>
                <label>{current_key}</label>
                <Input
                  type="range"
                  name={current_key}
                  min={0}
                  max={10}
                  value={workoutRecommendationsMacros.body_parts_distribtions[current_key]}
                  onChange={onBodyPartsMacrosChangeHandler}
                />{workoutRecommendationsMacros.body_parts_distribtions[current_key]}
              </Form.Field>
            )
          }
          )
          }
        </Form.Group>
        <br></br>
        <Form.Field>
          <Button inverted color="yellow" type='submit'>workout recommendations</Button>
        </Form.Field>

      </Form>
    </Container>
  )
}

export default WorkoutRecommendations