
import React from "react";

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';


function WeightInput({ userFitnessStateGoal, setUserFitnessStateGoal, units, unit, setUnit}) {

    const handleUnitChange = (event, newValue) => {
        setUnit(newValue);
        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                body_physical_measurement: { ...prevState.body_physical_measurement, weight_unit: units.weight_unit[newValue] },
            };
        });
        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                body_physical_measurement: { ...prevState.body_physical_measurement, height_unit: units.height_unit[newValue] },
            };
        });
    };
    const onChangeHandler = ({ target: { name, value } }) => {
        setUserFitnessStateGoal((prevState) => {
            return {
                ...prevState,
                body_physical_measurement: { ...prevState.body_physical_measurement, [name]: value },
            };
        });
    };
    return (
        <Box sx={{
            width: '100%', '& > :not(style)':
                { m: 2, width: '34ch' }
        }}
            component="form"
            noValidate
            autoComplete="off"
        >
            <Tabs value={unit} onChange={handleUnitChange} >
                <Tab label="US Units" value="us_units" />
                <Tab label="Metric Units" value="metric_units" />
            </Tabs>

            <FormControl sx={{ m: 2, width: '100%' }} variant="outlined">
                <TextField
                    id="outlined-basic"
                    name="weight"
                    label="Weight"
                    variant="outlined"
                    value={userFitnessStateGoal.body_physical_measurement.weight}
                    onChange={onChangeHandler}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{units.weight_unit[unit]}</InputAdornment>
                    }}
                />
                <br></br>

                <TextField
                    id="outlined-basic"
                    name="height"
                    label="Height"
                    variant="outlined"
                    value={userFitnessStateGoal.body_physical_measurement.height}
                    onChange={onChangeHandler}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{units.height_unit[unit]}</InputAdornment>
                    }}
                />

                <br></br>

                <TextField
                    id="outlined-basic"
                    name="age"
                    label="Age"
                    variant="outlined"
                    value={userFitnessStateGoal.body_physical_measurement.age}
                    onChange={onChangeHandler}
                />
                <br></br>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                >Biological Sex</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="gender"
                    value={userFitnessStateGoal.body_physical_measurement.gender}
                    onChange={onChangeHandler}
                >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default WeightInput;