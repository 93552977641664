import React from "react";

import MovementForm from "./MovementForm"
import SetsGoals from "./SetsGoals"
import RepsGoals from "./RepsGoals"

function Movement({
    movement, setMovement, allMovements,
    insideRound,
    setsGoals, setSetsGoals,
    repsGoals, setRepsGoals }) {

    return (
        <section>
            <MovementForm
                movement={movement}
                setMovement={setMovement}
                allMovements={allMovements}
            />
            {!insideRound &&
                <SetsGoals
                    setsGoals={setsGoals}
                    setSetsGoals={setSetsGoals}
                />
            }
            <RepsGoals
                repsGoals={repsGoals}
                setRepsGoals={setRepsGoals}
                insideRound={insideRound}
            />

        </section>
    );
}

export default Movement;