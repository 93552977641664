
import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import { useRouteMatch, Link, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import axios from "axios";
import {
    movementDefault,
    sessionEntryDefault,
    setsGoalsDefault,
    repsGoalsDefault
} from "../Session/Defaults"
import ReactCountdownClock from "react-countdown-clock"
import Webcam from "react-webcam";
import AWS from "aws-sdk"
import { isMobile, browserName } from 'react-device-detect';
import { Container, Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuid } from 'uuid';

const serverURL = process.env.REACT_APP_EXPRESS_SERVER_API_URL;
const sessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_SESSIONS_SUFFIX;
const userSessionsSuffix = process.env.REACT_APP_EXPRESS_SERVER_USER_SESSIONS_SUFFIX;
const sessionsURL = serverURL + sessionsSuffix;
const userSessionsURL = serverURL + userSessionsSuffix;

const flaskServerURL = process.env.REACT_APP_FLASK_SERVER_API_URL;
const userFeaturesSuffix = process.env.REACT_APP_FLASK_SERVER_USER_FEATURES_SUFFIX;
const userFeaturesFlaskURL = flaskServerURL + userFeaturesSuffix

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION;
var s3 = new AWS.S3({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region
});
// console.log(userFeaturesFlaskURL)

var mimeType = "video/mp4";
if (browserName === "Chrome" && !isMobile) {
    mimeType = "video/webm"
}

function Looper() {
    const location = useLocation()
    const [session, setSession] = useState({
        sessionMetadata: null,
        sessionEntryList: []
    });
    const { params: { sessionId } } = useRouteMatch();
    const [movement, setMovement] = useState(movementDefault);
    const [movementName, setMovementName] = useState("");
    const [sessionEntryList, setSessionEntryList] = useState(null);
    const [sessionEntry, setSessionEntry] = useState(sessionEntryDefault);
    const [sessionEntryIndex, setSessionEntryIndex] = useState(0);
    const [targetGoals, setTargetGoals] = useState(null);
    const [setsGoals, setSetsGoals] = useState(setsGoalsDefault);
    const [setIndex, setSetIndex] = useState(0);
    const [videoID, setVideoID] = useState(0);
    const [timestampStart, setTimetampStart] = useState(null);
    const [timestampEnd, setTimetampEnd] = useState(null);
    const [videoKey, setVideoKey] = useState("")
    const [videoUploadSuccess, setVideoUploadSuccess] = useState(false)
    const [view, setView] = useState({
        initial: true,
        movementGoals: false,
        workout: false,
        final: false
    });
    const [userDirectory, setUserDirectory] = useState("")
    const [userLooperSessionId, setUserLooperSessionId] = useState(new Date());
    // const user = "test";
    const webcamRef = useRef(null);
    var mediaRecorderRef = useRef(null);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const { user, isAuthenticated } = useAuth0();
    const [userSessionURL, setUserSessionURL] = useState("user_sessions/user_id");
    const [userId, setUserId] = useState("");
    
    useEffect(() => {
        if (user != undefined) {
            setUserId(user.sub);
            setUserSessionURL("user_sessions/user_id/" + user.sub);
        }
    }, [user]);

    const handleStartCapture = useCallback(() => {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: mimeType
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
        setTimetampStart(new Date());
    }, [webcamRef, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCapture = useCallback(() => {
        mediaRecorderRef.current.stop();
        setTimetampEnd(new Date());
    }, []);

    const handleUploadCapture = useCallback(async () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/mp4"
            });
            const url = URL.createObjectURL(blob);
            let key = "user_videos/" + userDirectory + "/" + timestampStart.getTime().toString() + "_" + timestampEnd.getTime().toString() + ".mp4";
            // console.log("Nickname: " + userDirectory);
            // console.log("Movement Name: " + movement.movementName);
            // console.log("Set Number: " + setIndex + 1);
            // console.log("Start Time: " + timestampStart);
            // console.log("End Time: " + timestampEnd);
            // console.log("S3 Key: " + key);
            let sessionIdString = (typeof sessionId != "undefined") ? sessionId.toString() : "";
            let userObj = (typeof user != "undefined") ? user : { "nickname": "undefined" };
            let userVideoUUID = uuid().toString();
            let userSession = {
                "movement": movement,
                "user": userObj,
                "user_video": {
                    "bucket": "workout-vision-public",
                    "key": key
                },
                "user_looper_session_id": userLooperSessionId.getTime().toString(),
                "session_id": sessionIdString,
                "user_video_id": userVideoUUID,
                "timestamp_start": timestampStart.getTime(),
                "timestamp_end": timestampEnd.getTime(),
                "target_goals": targetGoals,
                "session_state": {
                    "setNumber": setIndex + 1
                }
            };
            var params = {
                Bucket: "workout-vision-public",
                Key: key,
                Body: blob,
                ContentType: 'video/mp4',
                ACL: 'public-read'
            };
            var putObjectPromise = s3.putObject(params).promise();

            var handlePostUserSessionData = () => {
                try {
                    axios({
                        url: userSessionsURL,
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: userSession,
                    })
                    // console.log({ userSession });
                    let success = true;
                    return success;
                }
                catch ({
                    response: {
                        data: { error },
                    },
                }) {
                    // console.log(error.message);
                    let success = false;
                    return success;
                }

            }

            var triggerFlask = () => {
                try {
                    axios({
                        url: userFeaturesFlaskURL,
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*"
                        },
                        data: userSession,
                    })
                    let success = false;
                    return success;
                }
                catch ({
                    response: {
                        data: { error },
                    },
                }) {
                    // console.log(error.message);
                    let success = false;
                    return success;
                }
            }


            putObjectPromise.then(function (data) {
                // console.log(data)
                console.log("Upload to S3 was SUCCESSFUL");
                return true
            }).catch(function (err) {
                // console.log(err);
                console.log("Upload to S3 FAILED");
                return false
            }).then((success) => {
                if (success) {
                    let userSession = handlePostUserSessionData();
                    return { success, userSession };
                }
                else {
                    let userSession = handlePostUserSessionData();
                    return { success, userSession };
                }
            })
                .then((success, userSession) => {
                    if (success) {
                        triggerFlask(userSession);
                        return success
                    }
                })

            window.URL.revokeObjectURL(url);
            setRecordedChunks([]);
        }
    }, [recordedChunks]);

    const videoConstraints = {
        facingMode: "environment"
    };

    useEffect(() => {
        if (location.state && location.state.inputSession) {
            const fetchData = async () => {
                await setSession(location.state.inputSession);
                return location.state.inputSession;
            };
            fetchData()
                .then((retrievedSession) => {
                    let retrievedSessionEntryList = retrievedSession.sessionEntryList;
                    setSessionEntryList(retrievedSessionEntryList);
                    return retrievedSessionEntryList;
                })
                .then((retrievedSessionEntryList) => {
                    let firstMovement = retrievedSessionEntryList[0].movement;
                    let firstTargetGoals = retrievedSessionEntryList[0].targetGoals;
                    let firstSetsGoals = firstTargetGoals.setsGoals;
                    setMovement(firstMovement);
                    setTargetGoals(firstTargetGoals);
                    setSetsGoals(firstSetsGoals);
                })
        }
    }, []
    );

    useEffect(() => {
        if (sessionId) {
            const fetchData = async () => {
                return await axios.get(`${sessionsURL}/${sessionId}`);
            };
            fetchData()
                .then(({ data: { session } }) => {
                    setSession(session);
                    return session
                })
                .then((retrievedSession) => {
                    let retrievedSessionEntryList = retrievedSession.sessionEntryList;
                    setSessionEntryList(retrievedSessionEntryList);
                    return retrievedSessionEntryList;
                })
                .then((retrievedSessionEntryList) => {
                    let firstMovement = retrievedSessionEntryList[0].movement;
                    let firstTargetGoals = retrievedSessionEntryList[0].targetGoals;
                    let firstSetsGoals = firstTargetGoals.setsGoals;
                    setMovement(firstMovement);
                    setTargetGoals(firstTargetGoals);
                    setSetsGoals(firstSetsGoals);
                })
        }
    }, [sessionId]);

    useEffect(() => {
        if (typeof user === "undefined") {
            setUserDirectory("undefined");
        }
        else {
            setUserDirectory(user.nickname);
        }
        // console.log({ user })
    },
        [user, isAuthenticated])

    const updateSetsMovement = () => {
        if (sessionEntryIndex < session.sessionEntryList.length && setIndex < setsGoals.setsNumber - 1) {
            setSetIndex(setIndex + 1);
            setView({
                initial: false,
                movementGoals: true,
                workout: false,
                final: false,
            })
        }
        else if (sessionEntryIndex < session.sessionEntryList.length - 1) {
            setMovement(session.sessionEntryList[sessionEntryIndex + 1].movement);
            setTargetGoals(session.sessionEntryList[sessionEntryIndex + 1].targetGoals)
            setSetsGoals(session.sessionEntryList[sessionEntryIndex + 1].targetGoals.setsGoals);
            setSessionEntryIndex(sessionEntryIndex + 1);
            setSetIndex(0);
            setView({
                initial: false,
                movementGoals: true,
                workout: false,
                final: false,
            })
        }
        else {
            setView({
                initial: false,
                movementGoals: false,
                workout: false,
                final: true,
            })

        }

    }

    const updateViewToUpload = () => {
        setView({
            initial: false,
            movementGoals: false,
            workout: false,
            final: false,
            upload: true,
        })
    }

    const updateViewToMovement = () => {
        setView({
            initial: false,
            movementGoals: true,
            workout: false,
            final: false,
        })
    }

    const updateViewToWorkout = () => {
        setView({
            initial: false,
            movementGoals: false,
            workout: true,
            final: false,
            capture: true
        })
    }

    return (
        <section>
            {view.initial && (
                <section>
                    <Container>
                        <Row>
                            <Col md={10} style={{ justifyContent: 'left' }}>
                                <h1>{"GET READY TO WORKOUT"}</h1>
                            </Col>

                            <Col sm={2} style={{ justifyContent: 'right' }}>
                                <ReactCountdownClock seconds={5}
                                    color="#FF4500"
                                    alpha={1}
                                    size={150}
                                    onComplete={updateViewToMovement}
                                />
                            </Col>
                        </Row>
                    </Container>

                </section>
            )
            }

            {view.movementGoals && (
                <section>
                    <Container>
                        <Row>
                            <Col md={10} style={{ justifyContent: 'left' }}>
                                <h1>{"REST"}</h1>
                            </Col>
                            <Col sm={2} style={{ justifyContent: 'right' }}>
                                <ReactCountdownClock seconds={(setsGoals.restTimeBetweenSets) ? setsGoals.restTimeBetweenSets : 5}
                                    color="#FFFFFF"
                                    alpha={1}
                                    size={150}
                                    onComplete={() => { handleStartCapture(); updateViewToWorkout(); }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
            }

            {view.workout && (
                <section>
                    <Container>
                        <Row>
                            <Col md={10} style={{ justifyContent: 'left' }}>
                                <h1>{"WORKOUT"}</h1>
                                <h1>{movement.movementName}</h1><h1>Set {setIndex + 1} / {setsGoals.setsNumber}</h1>
                            </Col>
                            <Col sm={2} style={{ justifyContent: 'right' }}>
                                <ReactCountdownClock seconds={setsGoals.timePerSet}
                                    color="#28a745"
                                    alpha={1}
                                    size={150}
                                    onComplete={() => { handleStopCapture(); updateViewToUpload(); }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
            }

            {view.upload && (
                <section>
                    <Container>
                        <Row>
                            <Col md={10} style={{ justifyContent: 'left' }}>
                                <h1>{"UPLOAD"}</h1>
                            </Col>
                            <Col sm={2} style={{ justifyContent: 'right' }}>
                                <ReactCountdownClock seconds={0.5}
                                    color="#FF4500"
                                    alpha={1}
                                    size={150}
                                    onComplete={() => { handleUploadCapture(); updateSetsMovement(); }
                                    }
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
            }

            {!view.final && !view.initial && (
                <section>
                    <Container>
                        <Row>
                            <Col >
                                <ReactPlayer
                                    playing={true}
                                    playsinline={true}
                                    muted={true}
                                    loop={true}
                                    url={movement.videoUrl}
                                    config={{
                                        youtube: {
                                            playerVars: {
                                                start: movement.videoStartTime,
                                                end: movement.videoEndTime
                                            }
                                        }
                                    }}
                                />
                            </Col>
                            <Col >
                                <Webcam
                                    audio={true}
                                    ref={webcamRef}
                                    muted={true}
                                    videoConstraints={videoConstraints}
                                    height={'100%'} width={'100%'}
                                />
                            </Col>
                        </Row>
                    </Container>



                </section>
            )
            }

            {view.final && (
                <section>
                    <Container>
                        <Row>
                            <Col >
                                <h1>{"END OF WORKOUT"}</h1>
                            </Col>
                        </Row>
                    </Container>
                    <container className="container">
                        <Fragment>
                            <Button
                                color="blue"
                                inverted
                                className="btn__submit--movement"
                                as={Link}
                                to={{ pathname: "/dashboard" }}
                            >
                                Go to Dashboard
                            </Button>

                            <Button
                                color="green"
                                inverted
                                className="btn__submit--movement"
                                as={Link}
                                to={{ pathname: "/sessions" }}
                            >
                                Try another workout session
                            </Button>

                            <Button
                                color="pink"
                                inverted
                                className="btn__submit--movement"
                                as={Link}
                                to={{ pathname: userSessionURL }}
                            >
                                View your videos and Analysis
                            </Button>
                        </Fragment>
                    </container>
                </section>
            )
            }

        </section>
    );
}

export default Looper;